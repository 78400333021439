import React from "react";

export default () => (
  <svg width="25.599" height="31.999" viewBox="0 0 25.599 31.999">
    <g id="Group_401" data-name="Group 401" transform="translate(-15259 -3666)">
      <path
        id="Path_1324"
        data-name="Path 1324"
        d="M110.318,36a3.875,3.875,0,0,1-3.868-4.077l.542-10.322a8.8,8.8,0,0,1,17.6,0l.542,10.322A3.875,3.875,0,0,1,121.266,36Z"
        transform="translate(15156.008 3654)"
        fill="#fe2e62"
      />
      <path
        id="Path_1325"
        data-name="Path 1325"
        d="M238.016,19.879,237.475,9.6A9.6,9.6,0,0,0,226,.184,9.6,9.6,0,0,1,233.727,9.6l.541,10.28A4.674,4.674,0,0,1,229.6,24.8h3.75a4.674,4.674,0,0,0,4.664-4.919Z"
        transform="translate(15043.925 3666.001)"
        fill="#17292d"
        opacity="0.14"
      />
      <path
        id="Path_1326"
        data-name="Path 1326"
        d="M88,162a1.2,1.2,0,0,1-1.2,1.2H65.2A1.2,1.2,0,0,1,64,162a10.014,10.014,0,0,1,8.8-9.927v-3.5a5.826,5.826,0,0,1-2.4-4.75V142a1.2,1.2,0,0,1,1.2-1.2h8.8a1.2,1.2,0,0,1,1.2,1.2v2a5.562,5.562,0,0,1-1.64,3.96,5.659,5.659,0,0,1-.76.636v3.477A10.014,10.014,0,0,1,88,162Z"
        transform="translate(15195.8 3534)"
        fill="#e9e9e9"
      />
      <path
        id="Path_1327"
        data-name="Path 1327"
        d="M122.578,321.17v.274l-3.2,1.921-3.2-1.92v-.274a10.008,10.008,0,0,0-5.908,2.9l5.367,8.229h7.481l5.367-8.23A10.009,10.009,0,0,0,122.578,321.17Z"
        transform="translate(15152.422 3364.902)"
        fill="#fff"
      />
      <path
        id="Path_1328"
        data-name="Path 1328"
        d="M73.6,22.326a4.646,4.646,0,0,0,.543-2.446L73.6,9.6a9.6,9.6,0,0,0-19.2,0l-.541,10.28a4.646,4.646,0,0,0,.543,2.446A10.764,10.764,0,0,0,51.2,30a2,2,0,0,0,2,2H74.8a2,2,0,0,0,2-2,10.764,10.764,0,0,0-3.2-7.674ZM55.457,19.964,56,9.684V9.6a8,8,0,1,1,16,0v.084l.541,10.28a3.058,3.058,0,0,1-.208,1.289,10.781,10.781,0,0,0-2.184-1.226l-.013-.006-.2-.081-.036-.014c-.059-.023-.119-.045-.178-.067l-.062-.022-.154-.054-.087-.03-.125-.041-.117-.038-.094-.028L68.93,19.6l-.057-.016-.187-.051h-.011q-.333-.085-.675-.149V17a6.387,6.387,0,0,0,2.4-5V10a2,2,0,0,0-2-2H59.6a2,2,0,0,0-2,2v1.819A6.684,6.684,0,0,0,60,16.952v2.433q-.342.062-.676.149h-.01l-.191.051-.056.015-.151.044-.094.028-.117.037-.125.041-.087.03-.154.054-.062.022c-.062.022-.119.044-.179.067l-.036.014-.2.081-.013.006a10.782,10.782,0,0,0-2.184,1.226A3.059,3.059,0,0,1,55.457,19.964ZM60,21.294,62.211,22.4,60,23.5ZM64,16.8q-.116,0-.234-.006A4.947,4.947,0,0,1,59.2,11.819V10a.4.4,0,0,1,.4-.4h8.8a.4.4,0,0,1,.4.4v2A4.805,4.805,0,0,1,64,16.8Zm-.312,1.592q.156.008.312.008a6.381,6.381,0,0,0,2.4-.466v1.96L64,21.331l-2.4-1.437V17.922A6.162,6.162,0,0,0,63.687,18.392ZM68,21.294V23.5L65.788,22.4ZM53.2,30.4a.4.4,0,0,1-.4-.4,9.155,9.155,0,0,1,1.995-5.714L58.782,30.4Zm7.492,0-4.764-7.305a9.25,9.25,0,0,1,1.259-.931l.062-.039.1-.062.13-.074.035-.02.161-.087h0q.353-.187.722-.345V24.8a.8.8,0,0,0,1.158.716L64,23.294l4.442,2.221A.8.8,0,0,0,69.6,24.8V21.535q.368.157.722.345h0l.161.087.035.02.13.074.1.062.063.039a9.25,9.25,0,0,1,1.257.931l-4.76,7.3Zm14.107,0H69.217L73.2,24.285A9.155,9.155,0,0,1,75.2,30,.4.4,0,0,1,74.8,30.4Z"
        transform="translate(15207.8 3666)"
        fill="#141f38"
      />
      <path
        id="Path_1329"
        data-name="Path 1329"
        d="M230.512,128h-4a2,2,0,0,1,2,2v2a6.4,6.4,0,0,1-4.392,6.07,6.153,6.153,0,0,0,1.68.322A6.4,6.4,0,0,0,232.512,132v-2A2,2,0,0,0,230.512,128Z"
        transform="translate(15045.688 3546)"
        fill="#c1c1ca"
        opacity="0.14"
      />
    </g>
  </svg>
);

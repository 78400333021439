import React, { Component } from "react";
// import "./style.css";
import { NavLink } from "react-router-dom";
import Editpen from "../../../assets/svg/ava/editpen";
import Back from "../../../assets/svg/ava/back";
import Logout from "../../../assets/svg/ava/logout";

export default class Header extends Component {
  state = {
    openSidebar: false,
    actions: false,
    cart: [],
  };

  open = () => {
    this.setState(
      (state) => ({
        openSidebar: !state.openSidebar,
      }),
      () => {
        if (this.state.openSidebar) {
          this.body.setAttribute("class", "opened_sidebar");
        } else {
          this.body.removeAttribute("class");
        }
      }
    );
  };

  // componentDidMount(){
  //   console.log("ff",this.props);
  // }

  // CloseSidebar = () => {
  //   this.setState({ openSidebar: false });
  // };
  // body = document.querySelector("body");

  // componentDidMount() {
  //   if (this.props.search || this.props.filter || this.props.back) {
  //     this.setState({ actions: true });
  //   }
  //   this.getProductCart();
  // }
  // componentWillUnmount() {
  //   this.setState({ openSidebar: false });
  //   this.body.removeAttribute("class");
  // }

  // componentDidUpdate(prevP) {
  //   if (this.props.cart && prevP.cart !== this.props.cart) {
  //     this.setState({ cart: this.props.cart });
  //   }
  // }

  // getProductCart = async () => {
  //   // const { data } = await getProductCart();

  //   fetch("https://zoodmarket-app.ir/api/user/v1/cart.php", {
  //     headers: { token: localStorage.getItem("token") },
  //   }).then((res) => {
  //     res
  //       .json()
  //       .then((res) =>
  //         this.setState(
  //           { total: getTotal(res.cart) },
  //           console.log("ttt", res.cart)
  //         )
  //       );
  //   });
  // };

  render() {
    return (
      <>
        <div>
          <div className={this.props.black ? `back podback` : "back"}>
            {this.props.back ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <Back />
              </span>
            ) : (
              ""
            )}

            {this.props.edit ? (
              <>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "spaceBetween",
                    marginTop: "40px",
                  }}
                  // className="useredit inlineB "
                >
                  <h4 className="title tibot"> پروفایل </h4>

                  <NavLink
                    className="logout top5 "
                    to={`/main/editProfile/ویرایش پروفایل`}
                  >
                    <Editpen />
                  </NavLink>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "40px",
                }}
              >
                <h4 className="title tibot"> {this.props.pageTitle}</h4>
                {this.props.exit ? (
                  <NavLink to={`/main/exitPop`} class="a">
                    <span
                      style={{
                        cursor: "pointer",
                        marginLeft: "20px",
                        marginTop: "8px",
                      }}
                      // onClick={() => window.history.back()}
                    >
                      <Logout />
                    </span>
                  </NavLink>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
        {/* <Sidebar
          close={this.open.bind(this)}
          openMenu={this.state.openSidebar}
        /> */}
        {/* <div className={`binobat_header ${this.props.bg ? `bg` : ``}`}>
          {this.props.back ? (
            <div
              className="back_btn display_flex align_items_center"
              onClick={() => window.history.back()}
              to={`${
                this.props.backTo ? `${this.props.backTo}` : `/main/home`
              }`}
            >
              <span className="mdi mdi-arrow-right" />
              <div className="page_title">{this.props.pageTitle}</div>
            </div>
          ) : (
            <div className="display_flex align_items_center">
              <span
                className="menu_icon mdi mdi-menu ml-15"
                onClick={() => this.open()}
              />
              <div className="page_title">{this.props.pageTitle}</div>
            </div>
          )}
          {this.state.actions ? (
            <div className="header_actions">
              {this.props.search ? (
                <Link className="search_btn" to="/search">
                  <span className="mdi mdi-magnify" />
                </Link>
              ) : (
                ""
              )}

              <Link className="cart_btn" to={`/main/cart`}>
                <span className="mdi mdi-cart-outline" />
                <span>{console.log(this.state.total)}</span>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div> */}
      </>
    );
  }
}

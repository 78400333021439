import { APIClient } from "./_api";

function Visit() {
  return APIClient.get(`/Visit`);
}

function MostVisit() {
  return APIClient.get(`/MostVisit`);
}

function Recommended() {
  return APIClient.get(`/Recommended`);
}

export { Visit, MostVisit, Recommended };

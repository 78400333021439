import React, { Component } from "react";

import { FlexboxGrid, Col } from "rsuite";
import { NavLink } from "react-router-dom";

import Search from "../../../assets/svg/ava/search";
import Home from "../../../assets/svg/ava/home";
import User from "../../../assets/svg/ava/profile";

// import './style.css';
export default class Box extends Component {
  state = { alert: false, cart: [], total: "" };

  // componentDidMount() {
  //   this.getProductCart();
  // }
  // timeout;
  // alertFunc = async () => {
  //   await this.setState({ alert: false });
  //   // console.log('cart', this.state.cart);
  //   if (this.timeout) {
  //     clearTimeout(this.timeout);
  //   }
  //   await this.setState({ alert: true });

  //   this.timeout = setTimeout(() => {
  //     this.setState({ alert: false });
  //   }, 3000);
  // };

  // getProductCart = async () => {
  //   fetch('https://zoodmarket-app.ir/api/user/v1/cart.php', {
  //     headers: { token: localStorage.getItem('token') }
  //   }).then(res => {
  //     res.json().then(res => {
  //       this.setState({ cart: res.cart, total: getTotal(res.cart) });
  //     });
  //   });
  // };

  render() {
    return (
      <FlexboxGrid.Item
        componentClass={Col}
        className="nav display"
        lg={24}
        md={24}
        sm={24}
        xs={24}
      >
        <FlexboxGrid.Item
          componentClass={Col}
          className="navitem display "
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <NavLink
            exact="true"
            to="/main/home"
            className="IconNav display flexd"
          >
            <Home />
            <h7 className="marginh8">خانه</h7>
          </NavLink>

          <NavLink
            exact="true"
            to="/main/search/جستجو"
            className="IconNav display flexd"
          >
            <Search />
            <h7 className="marginh8">جستجو</h7>
          </NavLink>

          <NavLink
            exact="true"
            to="/main/profile/پروفایل کاربری"
            className="IconNav display flexd"
          >
            {<User />}
            <h7 className="marginh8">کاربر</h7>
          </NavLink>
        </FlexboxGrid.Item>
      </FlexboxGrid.Item>
      // <>
      //   <div className="display_flex justify_content_around align_items_center footer_div">
      //     <span> مبلغ قابل پرداخت</span>
      //     <span>
      //       {this.state.cart && this.state.cart.length
      //         ? this.state.total.totalPrice
      //         : 0}
      //       {/* {console.log('cart', this.state.cart && this.state.total)} */}
      //     </span>
      //     <span>تومان</span>
      //     <span>
      //       {this.state.cart.length ? (
      //         // <Link className="cartLink" to={`/main/cart/${this.state.cart}`}>
      //         <Link className="cartLink" to={`/main/cart`}>
      //           <button type="button">سبد خرید</button>
      //         </Link>
      //       ) : (
      //         <div className="cartLink">
      //           <button onClick={this.alertFunc} type="button">
      //             سبد خرید
      //           </button>
      //         </div>
      //       )}
      //     </span>
      //     {this.state.alert ? (
      //       <div className="card-alert">سبد خرید شما خالی است!</div>
      //     ) : (
      //       ''
      //     )}
      //   </div>
      // </>
    );
  }
}

import React from "react";

export default () => (
  <svg id="Export" width="24" height="24" viewBox="0 0 24 24">
    <rect
      id="Rectangle_570"
      data-name="Rectangle 570"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="Path_151"
      data-name="Path 151"
      d="M2.483,2.483V21.517H14.345V24H0V0H14.345V2.483ZM13.517,19.862l-1.931-1.931L16,13.517H5.793V10.759H16.276l-4.69-4.414,1.931-1.931,7.448,7.448Z"
      transform="translate(2)"
      fill="#fff"
    />
  </svg>
);

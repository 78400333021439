import React from "react";

import Menu from "../Main_menu";

import { FlexboxGrid } from "rsuite";

function MenuLayout(WrapperComponent) {
  return function (props) {
    return (
      <FlexboxGrid className=" flexd ">
        {/* <div className="page_with_menu"> */}
        <WrapperComponent {...props} />
        <Menu />
        {/* </div> */}
      </FlexboxGrid>
    );
  };
}

export default MenuLayout;

import React, {  Fragment } from "react";

function TermsConditions({...props }) {
        return (
            <Fragment>
                <div className="about_page">
					{props.children}
				</div>
            </Fragment>
        );
}
export default TermsConditions;


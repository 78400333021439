import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import App from "../../components/App";
import Startup from "./Startup";
import LoginComponent from "../Authentication/Login";
// import Register from "../Authentication/Register";
import "../../../node_modules/rsuite/dist/styles/rsuite-default-rtl.css";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allWords: [],
      routes: [
        {
          route: "/",
          exact: true,
          component: Startup,
        },
        {
          route: "/login",
          exact: true,
          component: LoginComponent,
        },
        {
          route: "/main",
          exact: false,
          component: App,
        },
        // {
        //   route: "/register",
        //   exact: false,
        //   component: Register,
        // },
      ],
    };
  }
  // scroll to top on pages rendering
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  async componentDidMount() {
    this.scrollStep();
  }

  render() {
    return (
      <Switch>
        {this.state.routes.map((route, k) => (
          <Route
            key={k}
            exact={route.exact}
            path={route.route}
            component={route.component}
          />
        ))}
      </Switch>
    );
  }
}

import { APIClient } from '../_api';

function getProductCart() {
  return APIClient.get(`/cart.php`);
  // fetch('https://zoodmarket-app.ir/api/user/v1/cart.php', {
  //   headers: { token: '2c4d28d8379ffb1f79b850b022e3f8f1' }
  // }).then(res => {
  //   return res.json().then(res => {
  //     return res;
  //   });
  // });
}

function addProductToCart(value) {
  return APIClient.post(`/addtocart.php`, value);
}

export { getProductCart, addProductToCart };

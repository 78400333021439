import { APIClient } from "./_api";

function ContentGroupBy(categoryId) {
  return APIClient.get(`/ContentGroupBy/${categoryId}`);
}

function ContentById(contentId) {
  return APIClient.get(`/ContentById/${contentId}`);
}

function Search(value) {
  return APIClient.get(`/Content?name=${value}`);
}

function File(id) {
  return APIClient.get(`/File/${id}`);
}

export { ContentGroupBy, ContentById, File, Search };

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../components/Layouts/MainHeader";

// import Button from "../../components/button";
import Swal from "../../components/Alart-Sweet/sweet-alert";
import avatar from "../../assets/images/ava/MaskGroup3.png";
import Pencil from "../../assets/svg/ava/pencil";

import WomanOn from "../../assets/svg/ava/womanon";

import ManOn from "../../assets/svg/ava/manon";

import { getMe } from "../../api/me";
import { editProfile, Education } from "../../api/account";

import {
  FlexboxGrid,
  Col,
  SelectPicker,
  Toggle,
  Form,
  FormGroup,
  FormControl,
  Uploader,
  ControlLabel,
  ButtonToolbar,
  Button,
} from "rsuite";

export default class Profile extends Component {
  state = {
    name: "محمد کمال زاده",

    gender: false,
    picture: null,
    stateName: "",
    data: {},
    education: [],
    redirect: false,
  };

  initialValues = {
    name: this.state.data.name,
    education: "",
    sex: this.state.data.sex,
    picture: "",
  };
  componentDidMount() {
    this.getMe();
    this.getEducation();
  }

  onChange = async (name, value) => {
    if (name === "sex") {
      this.setState({ gender: !this.state.gender });
    }
    await this.setState({
      initialValues: { ...this.state.initialValues, [name]: value },
    });
    if (!this.state.gender === true) {
      this.setState({
        initialValues: { ...this.state.initialValues, sex: "مرد" },
      });
    } else {
      this.setState({
        initialValues: { ...this.state.initialValues, sex: "زن" },
      });
    }
  };

  handleChange = async (e) => {
    try {
      let file = e[0].blobFile;
      this.setState({
        initialValues: { ...this.state.initialValues, picture: file },
      });
    } catch (error) {}
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    try {
      await editProfile(this.state.initialValues);
      this.setState({ redirect: true });
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  getMe = async () => {
    const { data } = await getMe();
    if (data.data.sex === "مرد") {
      this.setState({
        gender: false,
      });
    } else {
      this.setState({
        gender: true,
      });
    }

    this.setState({
      initialValues: {
        name: data.data.name,
        education: data.data && data.data.education && data.data.education.id,
        sex: data.data.sex,
        picture: "",
      },
    });
    this.setState({ data: data.data });
  };

  getEducation = async () => {
    const { data } = await Education();
    let newData = [];
    data.data.forEach((x) => {
      newData = [...newData, { label: x.name, value: x.id }];
    });
    this.setState({ education: newData });
  };

  submit = (e) => {
    this.setState({ name: this.state.stateName });
    Swal.close();
  };

  // edit = e => {
  //   // this.setState({ name: e.target.value });
  //   SweetAlert({
  //     allowOutsideClick: true,
  //     width: "80vw",
  //     html: (
  //       <div className="edit_profile_div">
  //         <div className="edit_profile">ویرایش نام کاربری</div>
  //         <input
  //           onChange={e => {
  //             this.setState({ stateName: e.target.value });
  //           }}
  //         />
  //         <div>
  //           <Button gray className="profile_button" onClick={this.submit}>
  //             ثبت
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               Swal.close();
  //             }}
  //             gray
  //             className="profile_button"
  //           >
  //             انصراف
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   });
  // };

  render() {
    const { redirect, loading } = this.state;
    if (redirect) {
      return <Redirect to={`/main/info/پروفایل`} />;
    }
    if (loading) {
      return (
        <>
          <div className="loading_apge">
            <div class=" lds-dual-ring"></div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="backgroundColor">
          <FlexboxGrid>
            <FlexboxGrid.Item
              className="user"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Header
                pageTitle={this.props.match.params.title}
                // back={true}
                search={true}
                bg={true}
                edit={false}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              className=" userdetail display flexd  "
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Form className="display  flexd form2 margint toping">
                <FormGroup className="marginbottom">
                  <div style={{ cursor: "pointer" }} className="avatardad rel">
                    <img src={avatar} className="avatar" alt="" />
                    <div className="avatarcover absolute avatar">
                      <Uploader
                        // style={{
                        //   color: "none",
                        //   height: "300px",
                        //   marginTop: "25px",
                        //   backgroundColor: "none",
                        // }}
                        autoUpload={false}
                        action="//jsonplaceholder.typicode.com/posts/"
                        onChange={this.handleChange}
                        ref={(ref) => {
                          this.uploader = ref;
                        }}
                      />

                      <div className="pencil">
                        <Pencil />
                      </div>
                    </div>
                  </div>
                </FormGroup>
                {/* <FormGroup className="marginbottom">
                  <div className="padding">
                    <ControlLabel className="gray">شماره تلفن</ControlLabel>
                  </div>
                  <div className="rel">
                    <FormControl className="input inputSize margin " />
                  </div>
                </FormGroup> */}

                <FormGroup className="marginbottom ">
                  <div className="padding">
                    <ControlLabel className="gray">
                      نام و نام خانوادگی
                    </ControlLabel>
                  </div>
                  <div className="rel">
                    <FormControl
                      onChange={(e) => this.onChange("name", e)}
                      className="input inputSize margin "
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="padding">
                    <ControlLabel className="gray">تحصیلات</ControlLabel>
                  </div>
                  <div className="rel">
                    {/* <Panel
                      header="کاردانی"
                      className="input inputSize margin animate"
                      collapsible
                    >
                      <Paragraph />
                    </Panel> */}
                    <SelectPicker
                      onChange={(e) => this.onChange("education", e)}
                      className="input inputSize margin animate"
                      data={this.state.education}
                      appearance="default"
                      placeholder="تحصیلات"
                      // style={{ width: 224 }}
                    />
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className="padding">
                    <ControlLabel className="gray">جنسیت</ControlLabel>
                  </div>
                  <div className="input inputSize margin display svgColor">
                    <div className="sex">
                      {this.state.gender ? <p>زن</p> : <p>مرد</p>}
                    </div>

                    <div>
                      {/* <WomanOff /> */}
                      <ManOn />
                    </div>
                    <Toggle
                      onChange={(e) => this.onChange("sex", e)}
                      className="mytoggle"
                      checked={this.state.gender}
                      // onChange={(checked) => {
                      //   this.setState({ overflow: checked });
                      // }}
                    />
                    <div>
                      <WomanOn />

                      {/* <WomanOff /> */}
                    </div>
                  </div>
                </FormGroup>

                <FormGroup
                  style={{ marginBottom: "30px" }}
                  className="display fgap"
                >
                  <ButtonToolbar>
                    <Button
                      onClick={this.handleSubmit}
                      className="button inputSize"
                    >
                      ثبت{" "}
                    </Button>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          {/* <Menu /> */}
        </div>
      </>
    );
  }
}

import { APIClient } from '../_api';

function products(id) {
  return APIClient.get(`/product.php?id=${id}`);
}

function productDetails(id) {
  return APIClient.get(`/product.php?id=${id}`);
}

export { products, productDetails };

/** @format */

import axios from "axios";
const { REACT_APP_API_URL } = process.env;

// const version = 'v1';

function setUrl(url = "ava") {
  return `${REACT_APP_API_URL}/${url}`;
}
let baseURL = setUrl();
// http://zoodmarket-app.ir/api/v1/login.php/user/verify_otp

// http://zoodmarket-app.ir/api/user/v1/login.php

const APIClient = axios.create({ baseURL });

function setAuthToken(token) {
  APIClient.defaults.headers.Authorization = `Bearer ${token}`;
}

function unsetAuthToken() {
  APIClient.defaults.headers.Authorization = "";
}

export { APIClient, setAuthToken, unsetAuthToken };

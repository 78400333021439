import React, { Component } from "react";

// Components
import Header from "../../components/Layouts/MainHeader";
import { NavLink } from "react-router-dom";
import NoImage from "../../assets/images/avatars/empty2.png";

// api
import { addProductToCart } from "../../api/cart/cart";
import "./style.css";

class Cart extends Component {
  state = {
    cart: [],
  };

  // componentDidMount() {
  //   this.getProductCart();
  // }

  // getProductCart = async () => {
  //   // const { data } = await getProductCart();

  //   fetch('https://zoodmarket-app.ir/api/user/v1/cart.php', {
  //     headers: { token: localStorage.getItem('token') }
  //   }).then(res => {
  //     res.json().then(res => this.setState({ cart: res.cart }));
  //   });
  //   // this.setState({ cart: data.cart });
  // };
  // timeout;
  // add = async item => {
  //   clearTimeout(this.timeout);
  //   let value = {
  //     count: Number(item.count) + 1,
  //     id: item.id
  //   };
  //   let cartCopy = [...this.state.cart];
  //   let index = cartCopy.findIndex(x => x.id === item.id);
  //   if (index > -1) {
  //     await ++cartCopy[index].count;
  //   } else {
  //     cartCopy.push({ id: item.id, count: 1 });
  //   }
  //   this.setState({ cart: cartCopy });
  //   this.timeout = setTimeout(async () => {
  //     await addProductToCart(value);
  //     this.getProductCart();
  //   }, 1000);
  // };

  minus = async (item) => {
    clearTimeout(this.timeout);
    let value;
    if (item.count > 0) {
      value = {
        count: Number(item.count) - 1,
        id: item.id,
      };
    } else {
      value = {
        count: 0,
        id: item.id,
      };
    }
    let cartCopy = [...this.state.cart];
    let index = cartCopy.findIndex((x) => x.id === item.id);
    if (index > -1) {
      await --cartCopy[index].count;
    } else {
      cartCopy.push({ id: item.id, count: 1 });
    }
    this.setState({ cart: cartCopy });
    this.timeout = setTimeout(async () => {
      await addProductToCart(value);
      this.getProductCart();
    }, 1000);
  };

  deleteCart = async (item) => {
    let value;
    if (item.count > 0) {
      value = {
        count: 0,
        id: item.id,
      };
    }
    await addProductToCart(value);
    this.getProductCart();
  };

  deleteCart;

  render() {
    return (
      <div>
        <div className="HeaderFather">
          <Header
            pageTitle="سبد خرید"
            back={true}
            search={true}
            bg={true}
            edit={false}
          />
        </div>
        <div className="">
          {this.state.cart.length ? (
            this.state.cart.map((x) => {
              return (
                <>
                  <NavLink
                    className=""
                    // to={`/main/products/${x.cat2_id}/${x.name}`}
                  >
                    <ul className="cart-content">
                      <div className="cart-img">
                        <img src={x.image ? x.image : NoImage} alt="" />
                      </div>
                      <div className="row-content">
                        <div className="first-row-cart">
                          <div className="cart-title">{x.name}</div>
                          <div
                            onClick={() => this.deleteCart(x)}
                            className="delete-cart"
                          >
                            {" "}
                            حذف
                          </div>
                        </div>
                        <div className="secend-row-cart">
                          <div className="cart-title">{x.price}</div>
                          <div className="cart-count">
                            <span
                              onClick={() => this.add(x)}
                              className="mdi mdi-plus close_modal"
                            ></span>
                            <span>{x.count}</span>
                            <span
                              onClick={() => this.minus(x)}
                              className="mdi mdi-minus close_modal"
                            ></span>
                          </div>
                          <div className="delete-cart">
                            {" "}
                            {x.price * x.count}
                          </div>
                        </div>
                      </div>
                    </ul>
                  </NavLink>
                </>
              );
            })
          ) : (
            <div className="empty-cart">سبد خرید شما خالی میباشد</div>
          )}
        </div>
      </div>
    );
  }
}

export default Cart;

/** @format */

import React, { Component } from "react";

import ReactPlayer from "react-player";

import { FlexboxGrid, Col } from "rsuite";
import Header from "../../components/Layouts/MainHeader";
import SubPink from "../../assets/svg/ava/sub-pink";

import { ContentById, File } from "../../api/content";
import { fixUrl } from "../../utils";

export default class choose extends Component {
  state = {
    playing: false,
    myFile: { data: {}, message: "" },
    content: {
      id: "",
      name: "",
      picture: "",
      contentType: "",
      detail: {
        pages: "",
        writer: "",
        translator: "",
        publisher: "",
        edition: "",
        description: "",
      },
    },
    id: "",
    activeContent: false,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.CategoryByParent(id);
  }

  CategoryByParent = async (id) => {
    this.setState({ loading: true });
    try {
      const { data } = await ContentById(id);

      let value = data.data.detail.duration;

      await this.setState({
        content: data.data,
        value,
      });
      let myFile = await File(data.data.fileId);
      console.log(myFile);
      this.setState({
        myFile: myFile.data,
      });
      // console.log("category",this.state.content);
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  player = () => {
    this.setState({ playing: !this.state.playing });
  };

  render() {
    const styles = {
      backgroundColor: "white",
      color: "red",
    };
    const { content, myFile, loading } = this.state;
    if (loading) {
      return (
        <>
          <div className="loading_apge">
            <div class="lds-dual-ring"></div>
          </div>
        </>
      );
    }
    return (
      <div className="backgroundColor">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="user"
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            style={{ padding: "0px" }}
            xs={24}
          >
            <Header
              pageTitle={this.props.match.params.title}
              back={true}
              search={true}
              bg={true}
              black={true}
              edit={false}
            />
            <FlexboxGrid.Item
              className=" userdetail display flexd none start form3"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <FlexboxGrid.Item
                className=" display none flexa "
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                {myFile.data.url !== undefined ? (
                  <ReactPlayer
                    //  light
                    url={fixUrl(myFile.data.url)}
                    playing={true}
                    // volume={0}
                    style={styles}
                    controls
                    // muted
                    width="100%"
                    height="50%"
                  />
                ) : (
                  <div style={{ color: "red" }}>{myFile.message}</div>
                )}

                {/* <video src={myFile.data.url} className="titleimg" /> */}
              </FlexboxGrid.Item>
              <div className="summery margint">
                <h6>{content.name}</h6>
                <div className="detailmar">
                  <p className="sumparag">{content.detail.description}</p>
                </div>
                <div className="detailmar">
                  <p className="inlineB marginr">{content.detail.duration}</p>
                </div>

                <div>
                  <p className="inlineB marginr">ترجمه و روایت : {content.detail.publisher}</p>
                </div>
                <div className="display subdad">
                  <div className="sub display">
                    <p className="marginh7">{content.subscription && content.subscription.name}</p>
                    <SubPink />
                  </div>
                </div>
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

import React, { Component } from "react";

import * as text from "../../../api/Statics/statics";

import "./index.css";
import SupportImg from "../../../assets/images/ZoodMarket/support.jpg";
import Content from "./text";
import Header from "../../../components/Layouts/MainHeader";

class Support extends Component {
  state = {
    aboutText: [],
  };
  GetTextAbout = async () => {
    try {
      const { data } = await text.Terms();

      if (data) {
        this.setState({ aboutText: data.data.GeneralContentLocalizes[0] });
      } else {
        this.setState({ noData: true });
      }
    } catch (error) {
      console.error(error);
    }
  };
  componentDidMount() {
    this.GetTextAbout();
  }

  render() {
    return (
      <>
        <Header pageTitle={"پشتیبانی"} back={true} bg={true} edit={false} />
        <div className="columns pg_content">
          <Content>
            <img src={SupportImg} alt="" />
            <div className="HelpButtondiv">
              <button type="button" className="HelpButton">
                تماس با پشتبانی فروشگاه : ۰۲۵۳۷۸۴۵۲۰۲
              </button>
            </div>
            <div className="SendMessageText">
              <span>ارسال انتقاد و پیشنهاد به مدیریت</span>
              <textarea rows={10} placeholder="متن پیام" />
              <div>
                <button className="btnsed blueColor">ارسال پیام</button>
                <button className="btnsed grayColor">سوابق پیام‌ها</button>
              </div>
            </div>
          </Content>
        </div>
      </>
    );
  }
}

export default Support;

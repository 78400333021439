import React from "react";

export default () => (
  <svg width="55" height="59" viewBox="0 0 55 59">
    <g
      id="Ecommerce-transfer_money-buy-smartphone-hands"
      data-name="Ecommerce-transfer money-buy-smartphone-hands"
      transform="translate(-2 -2)"
    >
      <path
        id="Path_263"
        data-name="Path 263"
        d="M56.293,14.293l1.414,1.414,4-4a1,1,0,0,0,0-1.414l-4-4L56.293,7.707,58.586,10H47v2H58.586Z"
        transform="translate(-5)"
        fill="white"
      />
      <path
        id="Path_264"
        data-name="Path 264"
        d="M50.293,23.707l1.414-1.414L49.414,20H61V18H49.414l2.293-2.293-1.414-1.414-4,4a1,1,0,0,0,0,1.414Z"
        transform="translate(-5 -0.243)"
        fill="white"
      />
      <path
        id="Path_265"
        data-name="Path 265"
        d="M55.927,30.684a3.47,3.47,0,0,0-4.959-.207,3.6,3.6,0,0,0-3.135-1.927,3.066,3.066,0,0,0-1.2.226,3.475,3.475,0,0,0-1.155.728,3.627,3.627,0,0,0-3.144-1.937,3.439,3.439,0,0,0-1.833.541V11.833A3.807,3.807,0,0,0,36.833,7.9,3.439,3.439,0,0,0,35,8.441V4.95A2.864,2.864,0,0,0,32.25,2h-22A2.864,2.864,0,0,0,7.5,4.95V24.617H6.583A4.759,4.759,0,0,0,2,29.533v4.022L3.833,54.166v5.851A.952.952,0,0,0,4.75,61H24.917a.952.952,0,0,0,.917-.983V54.431l3.41-5.231h1.971l1.036,3.117v7.7a.952.952,0,0,0,.917.983H51.5a.952.952,0,0,0,.917-.983v-7.7l4.207-12.646A7.334,7.334,0,0,0,57,37.361V33.467A4.084,4.084,0,0,0,55.927,30.684ZM24,59.033H5.667V55.1H24Zm.44-5.9H5.584L3.833,33.467V29.533a2.864,2.864,0,0,1,2.75-2.95H13a2.957,2.957,0,0,1,0,5.9H8.417a.952.952,0,0,0-.917.983V46.25a2.864,2.864,0,0,0,2.75,2.95H27.007Zm-14.19-5.9a.952.952,0,0,1-.917-.983V43.3h16.5V41.333H9.333V34.45H13a4.759,4.759,0,0,0,4.583-4.917A4.759,4.759,0,0,0,13,24.617H9.333V4.95a.952.952,0,0,1,.917-.983h22a.952.952,0,0,1,.917.983V25.167a3.418,3.418,0,0,0-1.843-.551,3.807,3.807,0,0,0-3.658,3.924v8.82a7.44,7.44,0,0,0,.376,2.321l2.512,7.552Zm26.583,11.8h-2.75v-5.9h2.75Zm4.583,0h-2.75v-5.9h2.75Zm4.583,0H43.25v-5.9H46Zm4.583,0h-2.75v-5.9h2.75Zm4.583-21.673a5.316,5.316,0,0,1-.266,1.652L50.858,51.167H33.808L29.766,39.023a5.425,5.425,0,0,1-.266-1.662V28.54a1.838,1.838,0,1,1,3.667.01v7.867H35V11.833a1.838,1.838,0,1,1,3.667,0V36.417H40.5V31.5a1.838,1.838,0,1,1,3.667,0v4.917H46V32.483a2.023,2.023,0,0,1,.541-1.387,1.638,1.638,0,0,1,.706-.472,1.608,1.608,0,0,1,.587-.108,1.909,1.909,0,0,1,1.833,1.967V37.4H51.5V33.467a1.838,1.838,0,1,1,3.667,0Z"
        fill="white"
      />
      <path
        id="Path_266"
        data-name="Path 266"
        d="M23,6A10,10,0,1,0,33,16,10,10,0,0,0,23,6Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,23,24Z"
        transform="translate(-1.375)"
        fill="white"
      />
      <path
        id="Path_267"
        data-name="Path 267"
        d="M26,18a3.01,3.01,0,0,1-2,2.82V22H22V20.82A3.01,3.01,0,0,1,20,18h2a1,1,0,1,0,1-1,3,3,0,0,1-1-5.82V10h2v1.18A3.01,3.01,0,0,1,26,14H24a1,1,0,1,0-1,1A3.009,3.009,0,0,1,26,18Z"
        transform="translate(-1.667)"
        fill="white"
      />
    </g>
  </svg>
);

import React from "react";

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_460" data-name="Group 460" transform="translate(-4951 -5559)">
      <g id="Group_459" data-name="Group 459" transform="translate(-8)">
        <path
          id="Path_1343"
          data-name="Path 1343"
          d="M8,1.422a24.53,24.53,0,0,1,3.2.089,4.123,4.123,0,0,1,1.511.267,3.122,3.122,0,0,1,1.511,1.511A4.123,4.123,0,0,1,14.489,4.8c0,.8.089,1.067.089,3.2a24.53,24.53,0,0,1-.089,3.2,4.123,4.123,0,0,1-.267,1.511,3.122,3.122,0,0,1-1.511,1.511,4.123,4.123,0,0,1-1.511.267c-.8,0-1.067.089-3.2.089a24.53,24.53,0,0,1-3.2-.089,4.123,4.123,0,0,1-1.511-.267,3.122,3.122,0,0,1-1.511-1.511A4.123,4.123,0,0,1,1.511,11.2c0-.8-.089-1.067-.089-3.2a24.53,24.53,0,0,1,.089-3.2,4.123,4.123,0,0,1,.267-1.511A3.192,3.192,0,0,1,2.4,2.4a1.5,1.5,0,0,1,.889-.622A4.123,4.123,0,0,1,4.8,1.511,24.53,24.53,0,0,1,8,1.422M8,0A26.264,26.264,0,0,0,4.711.089,5.488,5.488,0,0,0,2.756.444a3.48,3.48,0,0,0-1.422.889A3.48,3.48,0,0,0,.444,2.756,4.05,4.05,0,0,0,.089,4.711,26.264,26.264,0,0,0,0,8a26.264,26.264,0,0,0,.089,3.289,5.488,5.488,0,0,0,.356,1.956,3.48,3.48,0,0,0,.889,1.422,3.48,3.48,0,0,0,1.422.889,5.488,5.488,0,0,0,1.956.356A26.264,26.264,0,0,0,8,16a26.264,26.264,0,0,0,3.289-.089,5.488,5.488,0,0,0,1.956-.356,3.729,3.729,0,0,0,2.311-2.311,5.488,5.488,0,0,0,.356-1.956C15.911,10.4,16,10.133,16,8a26.264,26.264,0,0,0-.089-3.289,5.488,5.488,0,0,0-.356-1.956,3.48,3.48,0,0,0-.889-1.422A3.48,3.48,0,0,0,13.244.444,5.488,5.488,0,0,0,11.289.089,26.264,26.264,0,0,0,8,0M8,3.911A4.023,4.023,0,0,0,3.911,8,4.089,4.089,0,1,0,8,3.911m0,6.756A2.619,2.619,0,0,1,5.333,8,2.619,2.619,0,0,1,8,5.333,2.619,2.619,0,0,1,10.667,8,2.619,2.619,0,0,1,8,10.667m4.267-7.911a.978.978,0,1,0,.978.978.986.986,0,0,0-.978-.978"
          transform="translate(4959 5559)"
          fill="#fe2e62"
          fill-rule="evenodd"
        />
        <rect
          id="Rectangle_1497"
          data-name="Rectangle 1497"
          width="14"
          height="14"
          rx="4"
          transform="translate(4960 5560)"
          fill="#fe2e62"
        />
      </g>
      <path
        id="Path_1342"
        data-name="Path 1342"
        d="M16.905,24.266a4.023,4.023,0,0,0-4.089,4.089,4.089,4.089,0,1,0,4.089-4.089m0,6.756a2.667,2.667,0,1,1,2.667-2.667,2.619,2.619,0,0,1-2.667,2.667m4.267-7.911a.978.978,0,1,0,.978.978.986.986,0,0,0-.978-.978"
        transform="translate(4942.184 5538.89)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

import React from "react";

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Path_1337"
      data-name="Path 1337"
      d="M300.131,290.132a10,10,0,1,0,10,10A10.012,10.012,0,0,0,300.131,290.132Zm.769,14.615a.769.769,0,0,1-1.538,0v-6.154a.769.769,0,1,1,1.538,0Zm-.769-8.462a.769.769,0,1,1,.769-.769A.77.77,0,0,1,300.131,296.286Z"
      transform="translate(-290.131 -290.132)"
      fill="#252934"
    />
  </svg>
);

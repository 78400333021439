import React from "react";

export default () => (
  <svg width="120px" height="120px" viewBox="0 0 35 35">
    <path
      d="M25.302,0H9.698c-1.3,0-2.363,1.063-2.363,2.364v30.271C7.335,33.936,8.398,35,9.698,35h15.604
		c1.303,0,2.363-1.062,2.363-2.364V2.364C27.665,1.063,26.604,0,25.302,0z M15.004,1.704h4.992c0.157,0,0.286,0.128,0.286,0.287
		c0,0.158-0.129,0.286-0.286,0.286h-4.992c-0.158,0-0.286-0.128-0.286-0.286C14.718,1.832,14.846,1.704,15.004,1.704z M17.5,33.818
		c-0.652,0-1.184-0.529-1.184-1.183s0.529-1.182,1.184-1.182c0.655,0,1.185,0.528,1.185,1.182S18.152,33.818,17.5,33.818z
		 M26.022,30.625H8.981V3.749h17.042V30.625z M17.5,19.195c2.265,0,4.392,0.882,5.989,2.481c0.23,0.232,0.23,0.607,0,0.839
		c-0.23,0.232-0.606,0.232-0.84,0c-1.375-1.375-3.205-2.134-5.149-2.134c-1.945,0-3.773,0.759-5.15,2.134
		c-0.231,0.232-0.607,0.232-0.84,0c-0.23-0.23-0.23-0.606,0-0.839C13.109,20.077,15.236,19.195,17.5,19.195z M16.164,13.012
		c0,0.794-0.645,1.438-1.438,1.438c-0.795,0-1.439-0.644-1.439-1.438c0-0.795,0.645-1.438,1.439-1.438
		C15.52,11.573,16.164,12.217,16.164,13.012z M21.713,13.012c0,0.794-0.646,1.438-1.438,1.438c-0.795,0-1.438-0.644-1.438-1.438
		c0-0.795,0.646-1.438,1.438-1.438C21.067,11.573,21.713,12.217,21.713,13.012z"
    />
  </svg>
);

import React, { Component } from "react";
import { setAuthToken, APIClient } from "../api/_api";

import { Redirect } from "react-router-dom";

import Spinner from "./Loading";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Alert from "../components/Alart-Sweet";

// =============
// Routes
// =============
import Home from "../containers/Home";

//=======PRODUCT ZOODMARKET===========
import Products from "../containers/products";
import ProductDetails from "../containers/productDetails";
import Profile from "../containers/profile";
import EditProfile from "../containers/editProfile";
import Category from "../containers/category";
import Content from "../containers/content";
import ContentPodcast from "../containers/contentPodcast";
import ContentBook from "../containers/contentBook";
import ContentBookFile from "../containers/contentBookFile";
import ContentVideo from "../containers/contentVideo";
import Wallet from "../containers/profileTools/Wallet";
import Address from "../containers/profileTools/Address";
import Credit from "../containers/profileTools/Credit";
import Cart from "../containers/cart";
import Buy from "../containers/buy";
import BuyPop from "../containers/buyPop";
import ExitPop from "../containers/exitPop";
import History from "../containers/history";
import Search from "../containers/search";
import Info from "../containers/myInfo";
import { Notification } from "rsuite";
// ==============
// Static Pages
// ==============
import Support from "../containers/Static/Support";
import Help from "../containers/Static/Help";
import AboutUs from "../containers/Static/About-us";
import Condition from "../containers/Static/condition/Conditions";
// ====
import { UserContextProvider } from "./userContext";
import { getMe } from "../api/me";
import LastPayment from "../containers/LastPayment";

import "./App.css";
const List = (errors) => {
  return (
    <>
      {errors.map((error, k) => (
        <div key={k}>
          <span>{error.detail}</span>
        </div>
      ))}
    </>
  );
};

const ErrorHandler = () => {
  let [Errorr, Error_text] = "";

  switch (localStorage.getItem("language")) {
    case "fa":
      Errorr = "خطا";
      Error_text = "متاسفانه با خطا مواجه شدید.";
      break;
    case "en":
      Errorr = "Error";
      Error_text = "Sorry, you encountered an error.";
      break;
    case "ar":
      Errorr = "خطا";
      Error_text = "أسف حدث خطا";
      break;

    default:
      Errorr = "Error";
      Error_text = "Sorry, you encountered an error.";
      break;
  }

  // APIClient.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     if (error) {
  //       // createNotification("error", "filled", error.response.data.message);
  //     }
  //     // console.log(error.response);
  //     if (error.response && error.response.status === 422) {
  //       localStorage.removeItem("auth");
  //       window.location.replace("/login");
  //     }

  //     // if (error.response && error.response.status === 422) {
  //     //   let title = error.response.status,
  //     //     errors = [{ detail: Error_text }];
  //     //   if (
  //     //     error.response.data &&
  //     //     error.response.data.errors &&
  //     //     error.response.data.errors.length
  //     //   ) {
  //     //     title = error.response.data.errors[0].msg;
  //     //     errors = error.response.data.errors;
  //     //   }

  //     //   Alert(title, List(errors), "failed");
  //     // }
  //     return Promise.reject(error);
  //   }
  // );
  return <span />;
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      loading: true,
      isLoggedIn: false,
      allWords: [],
      user: {
        UserName: "محمد",
        Mobile: "09198698369",
      },
      routes: [
        {
          route: `/main/home`,
          exact: true,
          component: Home,
        },
        // ======
        // Static pages
        // ======
        {
          route: `/main/Support`,
          exact: true,
          component: Support,
        },
        {
          route: `/main/Help`,
          exact: true,
          component: Help,
        },
        {
          route: `/main/aboutUs`,
          exact: true,
          component: AboutUs,
        },
        {
          route: `/main/condition`,
          exact: true,
          component: Condition,
        },
        // =======
        // Dashboards
        // =======
        {
          route: `/main/editProfile/:title`,
          exact: true,
          component: EditProfile,
        },
        {
          route: `/main/buy/:title`,
          exact: false,
          component: Buy,
        },
        {
          route: `/main/buyPop/:id/:title`,
          exact: true,
          component: BuyPop,
        },
        {
          route: `/main/exitPop`,
          exact: true,
          component: ExitPop,
        },
        {
          route: `/main/categories/:id/:title`,
          exact: false,
          component: Category,
        },
        {
          route: `/main/content/:id/:title`,
          exact: false,
          component: Content,
        },
        {
          route: `/main/contentPodcast/:id/:title`,
          exact: false,
          component: ContentPodcast,
        },
        {
          route: `/main/contentBook/:id/:title`,
          exact: false,
          component: ContentBook,
        },

        {
          route: `/main/contentBookFile/:id/:title`,
          exact: false,
          component: ContentBookFile,
        },

        {
          route: `/main/contentVideo/:id/:title`,
          exact: false,
          component: ContentVideo,
        },
        {
          route: `/main/products/:id/:title`,
          exact: false,
          component: Products,
        },
        {
          route: `/main/productDetails`,
          exact: false,
          component: ProductDetails,
        },
        {
          route: `/main/cart`,
          exact: false,
          component: Cart,
        },
        {
          route: `/main/profile/:title`,
          exact: false,
          component: Profile,
        },
        {
          route: `/main/info/:title`,
          exact: true,
          component: Info,
        },
        {
          route: `/main/LastPayment`,
          exact: true,
          component: LastPayment,
        },
        {
          route: `/main/wallet/:title`,
          exact: false,
          component: Wallet,
        },
        {
          route: `/main/address/:title`,
          exact: false,
          component: Address,
        },
        {
          route: `/main/credit/:title`,
          exact: false,
          component: Credit,
        },
        {
          route: `/main/history/:title`,
          exact: false,
          component: History,
        },
        {
          route: `/main/search/:title`,
          exact: true,
          component: Search,
        },
      ],
    };
  }

  open = () => {
    this.setState(
      (state) => ({
        openMenu: !state.openMenu,
      }),
      () => {
        if (this.state.openMenu) {
          // TODO
          // get menu items from props for mapping sidebar menu items
        }
      }
    );
  };
  // scroll to top on pages rendering
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div>{message}</div>,
    });
  };

  componentDidMount() {
    APIClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error) {
          this.open("error", "خطا", error.response.data.message);
          // createNotification("error", "filled", error.response.data.message);
        }
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.clear();
          window.location.replace("/login");
        }

        // if (error.response && error.response.status === 422) {
        //   let title = error.response.status,
        //     errors = [{ detail: Error_text }];
        //   if (
        //     error.response.data &&
        //     error.response.data.errors &&
        //     error.response.data.errors.length
        //   ) {
        //     title = error.response.data.errors[0].msg;
        //     errors = error.response.data.errors;
        //   }

        //   Alert(title, List(errors), "failed");
        // }
        return Promise.reject(error);
      }
    );

    this.scrollStep();
    this.loader().then(() => this.setState({ loading: false }));
    const token = localStorage.getItem("token");
    if (token) {
      this.getUser(token);
    } else {
      this.setState({ user: {} });
    }
  }

  loader() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1500));
  }

  // user
  getUser = async (token) => {
    try {
      setAuthToken(token);
      const { data } = await getMe();
      this.userSet(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  userSet = async (user) => {
    if (!user.UserName) {
      user.UserName = user.FirstName + " " + user.LastName;
    }
    await this.setState({ user });
  };
  logout = () => {
    localStorage.removeItem("token");
    this.setState({ user: {} });
  };
  UNSAFE_componentWillMount() {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthToken(token);
    }
  }

  render() {
    const myContext = {
      getUser: this.getUser,
      logout: this.logout,
      user: this.state.user || "محمد",
    };

    const { loading } = this.state;
    let token = localStorage.getItem("token");
    if (this.props.location.pathname === "/main/buy/undefined") {
      this.open("error", "خطا", "لطفا دوباره امتحان کنید");
    }
    if (!token) {
      if (this.props.location.pathname !== "/main/condition") {
        return <Redirect to="/" />;
      }
    }
    if (loading) {
      return (
        <div className="loading_apge">
          <Spinner />
        </div>
      );
    }
    return (
      <>
        <ErrorHandler />
        <UserContextProvider value={myContext}>
          <Router>
            <Switch>
              {this.state.routes.map((route, k) => (
                <Route
                  key={k}
                  exact={route.exact}
                  path={route.route}
                  component={route.component}
                />
              ))}
            </Switch>
          </Router>
        </UserContextProvider>
      </>
    );
  }
}

export default App;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../components/Layouts/MainHeader";

// import Button from "../../components/button";
import Swal from "../../components/Alart-Sweet/sweet-alert";
import Buy from "../../assets/svg/ava/buy";
import Spend from "../../assets/svg/ava/spend";
import Menu from "../../components/Layouts/Main_menu";
import Editpen from "../../assets/svg/ava/editpen";
import More from "../../assets/svg/ava/more";

import { getMe } from "../../api/me";

import { FlexboxGrid, Col } from "rsuite";

export default class Profile extends Component {
  state = {
    name: "محمد کمال زاده",
    stateName: "",
    data: {},
  };

  componentDidMount() {
    this.getMe();
  }

  getMe = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await getMe();
      // console.log(data);
      this.setState({ data: data.data });
    } catch (error) {}
    this.setState({ loading: false });
  };

  submit = (e) => {
    this.setState({ name: this.state.stateName });
    Swal.close();
  };

  // edit = e => {
  //   // this.setState({ name: e.target.value });
  //   SweetAlert({
  //     allowOutsideClick: true,
  //     width: "80vw",
  //     html: (
  //       <div className="edit_profile_div">
  //         <div className="edit_profile">ویرایش نام کاربری</div>
  //         <input
  //           onChange={e => {
  //             this.setState({ stateName: e.target.value });
  //           }}
  //         />
  //         <div>
  //           <Button gray className="profile_button" onClick={this.submit}>
  //             ثبت
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               Swal.close();
  //             }}
  //             gray
  //             className="profile_button"
  //           >
  //             انصراف
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   });
  // };

  render() {
    const { data } = this.state;
    return (
      <>
        <div className="backgroundColor">
          <FlexboxGrid>
            <FlexboxGrid.Item
              className="user"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              {/* {console.log(this.props.match.params.title)} */}
              <Header
                pageTitle={this.props.match.params.title}
                // back={true}
                exit={true}
                search={true}
                bg={true}
                edit={false}
              />
            </FlexboxGrid.Item>
            {/* <div>
                <FlexboxGrid.Item
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  componentClass={Col}
                  className="userinfo display"
                >
                  <img src={data.picture} className="avatarpic" />
                  <div className=" inlineB profile">
                    <h4 className="name inlineB"> {data.name}</h4>

                    <div className="useredit inlineB">
                      <Editpen />
                    </div>
                    <p className="color">{data.phoneNumber}</p>
                  </div>
                </FlexboxGrid.Item>
              </div> */}
            {/* </FlexboxGrid.Item> */}
            <FlexboxGrid.Item
              className=" userdetail display flexd  "
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              {/* <img src={data.picture} className="avatarpic" />
                <FlexboxGrid.Item
                  lg={20}
                  md={20}
                  sm={20}
                  xs={20}
                  componentClass={Col}
                  className=" inlineB profile"
                >
                  <h4 className="name inlineB"> {data.name}</h4>
               
                  <p className="color">{data.phoneNumber}</p> */}
              {this.state.loading ? (
                <div className="loading_apge">
                  <div class="lds-dual-ring"></div>
                </div>
              ) : (
                ""
              )}

              <div className="gold display rightanime">
                <img src={data.picture} className="avatarpic" alt="" />
                <div className="width">
                  <h7 className="colorpink h7 "> {data.name}</h7>
                  <br />

                  <small className="small">{data.phoneNumber}</small>
                </div>
                <div style={{ cursor: "pointer" }} className="arows  ">
                  <NavLink className="fill" to={`/main/info/ پروفایل`}>
                    <More />
                  </NavLink>
                </div>
                {/* <div
                  style={{
                    cursor: "pointer",
                    paddingRight: "10px",
                    marginTop: "6px",
                  }}
                  className=""
                >
                  <NavLink className="fill" to={`/main/info/ پروفایل`}>
                    <More />
                  </NavLink>
                </div> */}
              </div>
              <div className="gold display rightanime">
                {data.subscription ? (
                  <>
                    <div className="width">
                      <h7 className="h7 ">
                        {data.subscription && data.subscription.priority === 0
                          ? "بدون اشتراک "
                          : data.subscription.name}
                      </h7>
                      <br />
                      <small className="small">
                        {data.subscription &&
                        data.subscription.priority === 0 ? (
                          ""
                        ) : (
                          <div> {data.subscription.duration} روز مانده</div>
                        )}
                      </small>
                    </div>

                    <div className="width">
                      <p className="money">{data.subscription.price} ریال</p>
                    </div>
                  </>
                ) : (
                  // "اشتراکی ثبت نشده است"

                  <>
                    <div className="width">
                      <h7 className="h7 colorpink">بدون اشتراک</h7>
                      <br />
                      <small className="small">
                        {" "}
                        مدت زمان باقی مانده : 0 روز
                      </small>
                    </div>
                  </>
                )}
              </div>

              <FlexboxGrid.Item
                className="  display   "
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <NavLink to={`/main/history/تاریخچه`} class="a rightanime">
                  <div className="Subscrip Bshadow">
                    <p>تراکنش ها</p>
                    <div className="iconpro">
                      <Spend />
                    </div>
                  </div>
                </NavLink>

                <NavLink
                  strict
                  to={`/main/buy/خرید اشتراک`}
                  class="a rightanime"
                >
                  <div className="Subscrip Bshadow">
                    خرید اشتراک
                    <div className="iconpro">
                      <Buy />
                    </div>
                  </div>
                </NavLink>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Menu />
        </div>
      </>
    );
  }
}

import React, { Component } from "react"

// import "./index.css";

// API
import { setAuthToken } from "../../../api/_api"
// import { getMe } from '../../../api/me';

// Lang
import { LangContextConsumer } from "../../../components/languageContext"
import { UserContextConsumer } from "../../../components/userContext"

// Components
import Waiting from "./Waiting"
import LoginForm from "./LoginForm"
import Verify from "./Verify"
import { Redirect } from "react-router-dom"

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verify: false,
      phoneNumber: null,
      name: null,
      steps: [{ name: "step1" }, { name: "step2" }, { name: "step3" }],
      status: "",
      step1: true,
      step2: false,
      nameField: true,
      error: null,
      redirect: false,
      Active: 1
    }
  }

  getUser = async (token) => {
    try {
      setAuthToken(token)
      // const { data } = await getMe();
      // this.userSet(data.data);
    } catch (error) {
      console.error(error)
    }
  }
  //  isIos = () => {
  //   const userAgent = window.navigator.userAgent.toLowerCase();
  //   return /iphone|ipad|ipod/.test( userAgent );
  // }
  // isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  componentDidMount() {
    // if (this.isIos() && !this.isInStandaloneMode()) {
    //   this.setState({ showInstallMessage: true });
    // }
    const userAgent = window.navigator.userAgent.toLowerCase()
    console.log("hello", userAgent)
    const token = localStorage.getItem("token")
    if (token) {
      this.getUser(token)
      this.setState({ redirect: true })
    } else {
      this.setState({ user: {}, redirect: false })
    }
  }

  RedirectToSite = () => {
    this.setState({ redirect: true })
  }

  loginData = async (data) => {
    await this.setState({
      phoneNumber: data.phoneNumber
    })
  }

  goNext = (page) => {
    this.setState({ Active: page })
  }

  registerOrLogin = (x) => {
    this.setState({ nameField: x })
  }

  sendStatus = (status) => {
    this.setState({ status })
    return status
  }
  render() {
    const Active = this.state.Active
    if (this.state.redirect) {
      return <Redirect to={`/main/home`} />
    }
    return (
      <UserContextConsumer>
        {(value) =>
          !value ? (
            <>
              {Active === 1 && (
                <LoginForm
                  sendStatus={this.sendStatus}
                  goNext={this.goNext}
                  loginData={this.loginData}
                  registerOrLogin={this.registerOrLogin}
                />
              )}
              {Active === 2 && (
                <Waiting
                  goNext={this.goNext}
                  sendStatus={this.sendStatus}
                  data={this.state}
                  nameField={this.state.nameField}
                  redirect={this.RedirectToSite}
                />
              )}
              {Active === 3 && (
                <Verify
                  goNext={this.goNext}
                  data={this.state}
                  nameField={this.state.nameField}
                  redirect={this.RedirectToSite}
                />
              )}
            </>
          ) : null
        }
      </UserContextConsumer>
    )
  }
}
Login.contextType = LangContextConsumer

import React from "react";

export default () => (
  <svg
    id="Alert"
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
  >
    <rect
      id="Rectangle_479"
      data-name="Rectangle 479"
      width="10"
      height="10"
      fill="none"
    />
    <path
      id="Path_1"
      data-name="Path 1"
      d="M9.37,8.75H6.254a1.246,1.246,0,1,1-2.492,0H.647a.6.6,0,0,1-.561-.437.669.669,0,0,1,.187-.688,2.519,2.519,0,0,0,1-2V3.75a3.738,3.738,0,1,1,7.477,0V5.625a2.519,2.519,0,0,0,1,2A.643.643,0,0,1,9.37,8.75Z"
      transform="translate(-0.062)"
      fill="#fe2e62"
      fill-rule="evenodd"
    />
  </svg>
);

import React, { Component } from "react";

// Components
import Header from "../../components/Layouts/MainHeader";

import Menu from "../../components/Layouts/Main_menu";
import { NavLink } from "react-router-dom";

import { CategoryByParent } from "../../api/category";
import { ContentGroupBy } from "../../api/content";
import Spinner from "../../components/Loading";

import { FlexboxGrid, Col } from "rsuite";
// api
// import { subCategories } from '../../api/categories/categories';

class Category extends Component {
  state = {
    myCategory: [],
    id: "",
    activeContent: false,
    loading: false,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.CategoryByParent(id);
  }

  componentWillReceiveProps(props) {
    // console.log("props",props);
    this.CategoryByParent(props.match.params.id);
  }

  CategoryByParent = async (id) => {
    // console.log("id",id);
    this.setState({
      loading: true,
    });
    try {
      const { data } = await CategoryByParent(id);
      if (!data.data) {
        this.setState({ activeContent: true });
        await ContentGroupBy(id);
        // console.log("dataContent",data);
      }

      // console.log("ssss",this.props.match.params.id);
      await this.setState({ myCategory: data.data });
      // console.log("category",this.state.myCategory);
    } catch (error) {
      console.error(error);
    }
    this.setState({
      loading: false,
    });
  };

  // componentDidUpdate(){
  //   console.log("ssss",this.props.match.params.id);
  //   this.getCategories();

  // }

  handleClick = (id) => {
    this.CategoryByParent(id);
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <>
          <div className="loading_apge">
            <div class="lds-dual-ring"></div>
          </div>
        </>
      );
    }
    return (
      <div className="backgroundColor">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="user"
            componentClass={Col}
            lg={24}
            md={24}
            style={{ padding: "0px" }}
            sm={24}
            xs={24}
          >
            <Header
              pageTitle={this.props.match.params.title}
              back={true}
              search={true}
              bg={true}
              edit={false}
            />
            <FlexboxGrid.Item
              className="userdetail display flexd  form3"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <FlexboxGrid.Item
                className="display flexd"
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <FlexboxGrid.Item
                  className="display flexd "
                  componentClass={Col}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <FlexboxGrid.Item
                    className="wrap rightanime rt"
                    componentClass={Col}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    {this.state.myCategory &&
                      this.state.myCategory.map((x) => {
                        return x.child.length ? (
                          <NavLink
                            className="item itemmarg"
                            onClick={() => this.handleClick(x.id)}
                            to={`/main/categories/${x.id}/${x.name}`}
                          >
                            <div className="category Bshadow display flexd ">
                              <img
                                src={x.picture}
                                className="catepic "
                                alt=""
                              />

                              <p className="catp">{x.name}</p>
                            </div>
                          </NavLink>
                        ) : (
                          <NavLink
                            className="item itemmarg"
                            onClick={() => this.handleClick(x.id)}
                            to={`/main/content/${x.id}/${x.name}`}
                          >
                            <div className="category Bshadow display flexd ">
                              <img
                                src={x.picture}
                                className="catepic "
                                alt=""
                              />
                              <p className="catp">{x.name}</p>
                            </div>
                          </NavLink>
                        );
                      })}
                  </FlexboxGrid.Item>
                </FlexboxGrid.Item>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
            {/* <div className="categoryList">
          <CategoryList categoreis={this.state.Category} />
        </div> */}
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Menu />
      </div>
    );
  }
}

export default Category;

import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import Header from "../../components/Layouts/MainHeader";

import { FlexboxGrid, Col, Button, ButtonToolbar } from "rsuite";

import { ContentById, File } from "../../api/content";

import SubPink from "../../assets/svg/ava/sub-pink";
export default class choose extends Component {
  state = {
    playing: false,
    myFile: { url: "" },
    content: {
      id: "",
      name: "",
      picture: "",
      contentType: "",
      detail: {
        pages: "",
        writer: "",
        translator: "",
        publisher: "",
        edition: "",
        description: "",
      },
    },
    id: "",
    activeContent: false,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.CategoryByParent(id);
  }

  CategoryByParent = async (id) => {
    this.setState({ loading: true });
    try {
      const { data } = await ContentById(id);
      let value = data.data.detail.duration;

      await this.setState({
        content: data.data,
        value,
      });
      let myFile = await File(data.data.fileId);
      this.setState({
        myFile: myFile.data.data,
      });
      // console.log("category",this.state.content);
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  player = () => {
    this.setState({ playing: !this.state.playing });
  };

  render() {
    const { content, loading } = this.state;
    if (loading) {
      return (
        <>
          <div className="loading_apge">
            <div class="lds-dual-ring"></div>
          </div>
        </>
      );
    }
    return (
      <div className="backgroundColor">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="user"
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            style={{ padding: "0px" }}
            xs={24}
          >
            <Header
              pageTitle={"کتاب خوان"}
              back={true}
              search={true}
              bg={true}
              black={true}
              edit={false}
            />
            <FlexboxGrid.Item
              className=" userdetail none display flexd start form3"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <FlexboxGrid.Item
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="titleimg"
              >
                <div className="absolute titlebook2 titleBook display start">
                  <p>
                    {" "}
                    {content.name} - {content.detail.writer}
                  </p>
                </div>
                <img src={content.picture} className="titleimg" alt="" />
              </FlexboxGrid.Item>

              <div className="summery margint">
                <h6>{content.name}</h6>
                <p className="sumparag">{content.detail.description}</p>
                <p>ترجمه : {content.detail.translator}</p>
                <p>انتشارات : {content.detail.publisher}</p>
                <p>نویسنده : {content.detail.writer}</p>
                <p>تعداد صفحات : {content.detail.pages}</p>
                <p> چاپ : {content.detail.edition}</p>
              </div>

              <div className="display subdad">
                <div className="sub display">
                  <p className="marginh7">
                    {content.subscription && content.subscription.name}
                  </p>
                  <SubPink />
                </div>
              </div>

              <ButtonToolbar>
                <Button className="button inputSize marginbot">
                  <NavLink
                    className=""
                    // onClick={() => this.handleClick(x.id)}
                    to={`/main/contentBookFile/${content.fileId}/${content.name}`}
                  >
                    {/* <div className="category Bshadow display flexd "> */}
                    {/* <img src={x.picture} className="catepic" /> */}
                    <p> باز کردن کتاب</p>
                    {/* </div> */}
                  </NavLink>
                </Button>
              </ButtonToolbar>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

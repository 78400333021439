import React, { Component } from "react";

// Components

import searchicon from "../../assets/svg/ava/Group-21.svg";
// import Search from "../../assets/svg/ava/search";
import Menu from "../../components/Layouts/Main_menu";
import { NavLink } from "react-router-dom";

import { Search } from "../../api/content";

import { FlexboxGrid, Col, InputGroup, Input } from "rsuite";
import Podcast from "../../assets/svg/ava/podcast";
import Book from "../../assets/svg/ava/book";
import Video from "../../assets/svg/ava/video";
import Subgray from "../../assets/svg/ava/subgray";

// api
// import { subCategories } from '../../api/categories/categories';

class Category extends Component {
  state = {
    content: [],
    id: "",
    activeContent: false,
    name: null,
    initialValues: {
      name: "",
    },
    subscription: {
      name: "",
    },
  };

  handleClick = async (id) => {
    this.setState({ loading: true });
    try {
      let { data } = await Search(this.state.initialValues.name);
      this.setState({ content: data.data });
      // console.log(data.data);
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  onChange = async (name, value) => {
    await this.setState({
      initialValues: { ...this.state.initialValues, [name]: value },
    });
  };

  render() {
    return (
      <div>
        <FlexboxGrid className=" flexd minheight2">
          <FlexboxGrid.Item
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            // style={{ padding: "0px" }}
            xs={24}
          >
            {/* <Header
              pageTitle={this.props.match.params.title}
              // back={true}
              // search={true}
              // bg={true}
            /> */}
            {/* <FlexboxGridoooooooooooo
              className=" userdetail display flexd start form3"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            > */}
            <FlexboxGrid.Item
              className="display margint"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <InputGroup className="search Bshadow margint ">
                <Input
                  onChange={(e) => this.onChange("name", e)}
                  placeholder="جستجو"
                  className="search "
                />
                <img onClick={this.handleClick} src={searchicon} alt="" />
              </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              className=" display flexd  margint"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <FlexboxGrid.Item
                className="display flexd"
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <FlexboxGrid.Item
                  className="display flexd "
                  componentClass={Col}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <FlexboxGrid.Item
                    className="wrap rightanime"
                    componentClass={Col}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    {this.state.loading ? (
                      <div className="loading_apge">
                        <div class="lds-dual-ring"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.content && this.state.content.length ? (
                      this.state.content.map((x) => {
                        return x.contentType === "Podcast" ? (
                          <NavLink
                            className="display flexd"
                            // onClick={()=>this.handleClick(x.id)}
                            to={`/main/contentPodcast/${x.id}/${x.name}`}
                          >
                            <div className="  display flexd ">
                              <img
                                src={x.picture}
                                className="item Bshadow"
                                alt=""
                              />
                              <div className="display">
                                <Podcast />
                                <p className="marginr pitem">{x.name}</p>
                              </div>
                              <div className="display   ">
                                <Subgray />
                                <p className="marginr subgray">
                                  {x.subscription && x.subscription.name}
                                </p>
                              </div>
                            </div>
                          </NavLink>
                        ) : x.contentType === "Book" ? (
                          <NavLink
                            className="display flexd"
                            // onClick={()=>this.handleClick(x.id)}
                            to={`/main/contentBook/${x.id}/${x.name}`}
                          >
                            <div className="  display flexd ">
                              <img
                                src={x.picture}
                                className="item Bshadow"
                                alt=""
                              />
                              <div className="display">
                                <Book />
                                <p className="marginr pitem">{x.name}</p>
                              </div>
                              <div className="display    ">
                                <Subgray />
                                <p className="marginr subgray">
                                  {x.subscription && x.subscription.name}{" "}
                                </p>
                              </div>
                            </div>
                          </NavLink>
                        ) : (
                          <NavLink
                            className="display flexd"
                            // onClick={()=>this.handleClick(x.id)}
                            to={`/main/contentVideo/${x.id}/${x.name}`}
                          >
                            <div className="  display flexd ">
                              <img
                                src={x.picture}
                                className="item Bshadow"
                                alt=""
                              />
                              <div className="display">
                                <Video />
                                <p className="marginr pitem">{x.name}</p>
                              </div>
                              <div className="display    ">
                                <Subgray />
                                <p className="marginr subgray">
                                  {x.subscription && x.subscription.name}
                                </p>
                              </div>
                            </div>
                          </NavLink>
                        );
                      })
                    ) : (
                      <FlexboxGrid.Item
                        style={{ color: "#c1c2cd", marginTop: "30px" }}
                      >
                        محتوایی موجود نمیباشد
                      </FlexboxGrid.Item>
                    )}
                  </FlexboxGrid.Item>
                </FlexboxGrid.Item>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
            {/* </FlexboxGridoooooooooooo> */}
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Menu />
      </div>
    );
  }
}

export default Category;

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { NavLink } from "react-router-dom";
import { FlexboxGrid, Col } from "rsuite";
import Header from "../../components/Layouts/MainHeader";
import Menu from "../../components/Layouts/Main_menu";

import { ContentGroupBy } from "../../api/content";
import Podcast from "../../assets/svg/ava/podcast";
import Book from "../../assets/svg/ava/book";
import Video from "../../assets/svg/ava/video";
import Subgray from "../../assets/svg/ava/subgray";

export default class choose extends Component {
  state = {
    content: [],
    data: { Video: [], Book: [], Podcast: [] },
    id: "",
    activeContent: false,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.CategoryByParent(id);
  }

  CategoryByParent = async (id) => {
    this.setState({
      loading: true,
    });
    try {
      const { data } = await ContentGroupBy(id);
      console.log(data);
      // console.log("dataContent",data);

      // console.log("ssss",this.props.match.params.id);
      this.setState({
        content: data.data && data.data.Book,
        data: data.data && data.data,
      });
      // console.log("category",this.state.content);
    } catch (error) {
      console.error(error);
    }
    this.setState({
      loading: false,
    });
  };

  getBooks = () => {
    this.setState({
      content: this.state.data && this.state.data.Book,
    });
  };

  getpodcasts = () => {
    this.setState({
      content: this.state.data && this.state.data.Podcast,
    });
  };

  getVideos = () => {
    this.setState({
      content: this.state.data && this.state.data.Video,
    });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="backgroundColor">
        {loading ? (
          <div className="loading_apge">
            {" "}
            <div class="lds-dual-ring"></div>
          </div>
        ) : (
          ""
        )}
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="user"
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            style={{ padding: "0px" }}
            xs={24}
          >
            <Header
              pageTitle={this.props.match.params.title}
              back={true}
              search={true}
              bg={true}
              edit={false}
            />
            <FlexboxGrid.Item
              className=" userdetail display flexd start form3"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <FlexboxGrid.Item
                className="display margint start "
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Link onClick={this.getBooks} className=" display light ">
                  کتاب
                </Link>
                <Link onClick={this.getpodcasts} className=" display light">
                  پادکست
                </Link>
                <Link onClick={this.getVideos} className="display light">
                  ویدیو
                </Link>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                className="userdetail display flexd  form3"
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <FlexboxGrid.Item
                  className="display flexd"
                  componentClass={Col}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <FlexboxGrid.Item
                    className="display flexd "
                    componentClass={Col}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <FlexboxGrid.Item
                      className="wrap "
                      componentClass={Col}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                    >
                      {this.state.content && this.state.content.length ? (
                        this.state.content.map((x) => {
                          return x.contentType === "Podcast" ? (
                            <NavLink
                              className="display flexd rightanime "
                              // onClick={()=>this.handleClick(x.id)}
                              to={`/main/contentPodcast/${x.id}/${x.name}`}
                            >
                              <div className="  display flexd ">
                                <img
                                  src={x.picture}
                                  className="item Bshadow"
                                  alt=""
                                />
                                <div className="display">
                                  <Podcast />
                                  <p className="marginr pitem">{x.name}</p>
                                </div>
                                <div className="display subdad2  ">
                                  <Subgray />
                                  <p className="marginr subgray">
                                    {x.subscription && x.subscription.name}
                                  </p>
                                </div>
                              </div>
                            </NavLink>
                          ) : x.contentType === "Book" ? (
                            <NavLink
                              className="display flexd rightanime"
                              // onClick={()=>this.handleClick(x.id)}
                              to={`/main/contentBook/${x.id}/${x.name}`}
                            >
                              <div className="  display flexd ">
                                <img
                                  src={x.picture}
                                  className="item Bshadow"
                                  alt=""
                                />
                                <div className="display">
                                  <Book />
                                  <p className="marginr pitem">{x.name}</p>
                                </div>
                                <div className="display subdad2  ">
                                  <Subgray />
                                  <p className="marginr subgray">
                                    {x.subscription && x.subscription.name}
                                  </p>
                                </div>
                              </div>
                            </NavLink>
                          ) : (
                            <NavLink
                              className="display flexd rightanime"
                              // onClick={()=>this.handleClick(x.id)}
                              to={`/main/contentVideo/${x.id}/${x.name}`}
                            >
                              <div className="  display flexd ">
                                <img
                                  src={x.picture}
                                  className="item Bshadow"
                                  alt=""
                                />
                                <div className="display">
                                  <Video />
                                  <p className="marginr pitem">{x.name}</p>
                                </div>
                                <div className="display  subdad2 ">
                                  <Subgray />
                                  <p className="marginr subgray">
                                    {x.subscription && x.subscription.name}
                                  </p>
                                </div>
                              </div>
                            </NavLink>
                          );
                        })
                      ) : (
                        <FlexboxGrid.Item
                          style={{ color: "#c1c2cd", marginTop: "30px" }}
                        >
                          محتوایی موجود نمیباشد
                        </FlexboxGrid.Item>
                      )}
                    </FlexboxGrid.Item>
                  </FlexboxGrid.Item>
                </FlexboxGrid.Item>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Menu />
      </div>
    );
  }
}

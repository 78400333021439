/** @format */

import React, { Component } from "react";

import announcer from "../../assets/svg/ava/Profile-9.svg";
import author from "../../assets/svg/ava/Compose.svg";
import time from "../../assets/svg/ava/Time.svg";
// import ReactPlayer from "react-player";

import { FlexboxGrid, Col } from "rsuite";
import Header from "../../components/Layouts/MainHeader";
import SubPink from "../../assets/svg/ava/sub-pink";

import { ContentById, File } from "../../api/content";
import { fixUrl } from "../../utils";

export default class choose extends Component {
  state = {
    playing: false,
    myFile: { data: {}, message: "" },
    content: {
      id: "",
      name: "",
      picture: "",
      contentType: "",
      detail: {
        pages: "",
        writer: "",
        translator: "",
        publisher: "",
        edition: "",
        description: "",
      },
    },
    id: "",
    activeContent: false,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.CategoryByParent(id);
  }

  CategoryByParent = async (id) => {
    this.setState({ loading: true });

    try {
      const { data } = await ContentById(id);
      // console.log("data ", data.data);
      let value = data.data.detail.duration;
      await this.setState({
        content: data.data,
        value,
      });
      let myFile = await File(data.data.fileId);
      this.setState({
        myFile: myFile.data,
      });
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  player = () => {
    this.setState({ playing: !this.state.playing });
  };

  //   <audio style={{ width: "inherit" }} controls>
  //   <source
  //     src={fixUrl() ${myFile.url}`}
  //     type="audio/ogg"
  //   />
  //   <source
  //     src={fixUrl() ${myFile.url}`}
  //     type="audio/mpeg"
  //   />
  //   Your browser does not support the audio element.
  // </audio>

  render() {
    const { content, myFile, loading } = this.state;
    if (loading) {
      return (
        <>
          <div className="loading_apge">
            <div class="lds-dual-ring"></div>
          </div>
        </>
      );
    }
    return (
      <FlexboxGrid>
        <FlexboxGrid.Item
          className="user"
          componentClass={Col}
          lg={24}
          md={24}
          sm={24}
          style={{ padding: "0px" }}
          xs={24}
        >
          <Header
            // pageTitle={this.props.match.params.title}
            back={false}
            search={false}
            bg={false}
            black={false}
            edit={false}
            style={{ height: "10px" }}
          />
          <FlexboxGrid.Item
            className="  display flexd start form3"
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <FlexboxGrid.Item
              className="  display flexd start "
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <div>
                <img src={content.picture} className="podcover" alt="" />
              </div>
              <div className="summery margint">
                <div className="align">
                  <h6>{content.name}</h6>
                  <h7 className="speaker">{content.detail.publisher}</h7>
                </div>
                <div className="marginbot">
                  <div className="detailmar">
                    <p className="sumparag">{content.detail.description}</p>
                  </div>
                  <div>
                    <img src={time} alt="" />
                    <p className="inlineB marginr">{content.detail.duration}</p>
                  </div>

                  <div>
                    <img src={announcer} alt="" />
                    <p className="inlineB marginr">گوینده : {content.detail.publisher}</p>
                  </div>

                  <div>
                    <img src={author} alt="" />
                    <p className="inlineB marginr">ترجمه و روایت: {content.detail.announcer}</p>
                  </div>
                </div>
              </div>

              <div className="display subdad">
                <div className="sub display">
                  <p className="marginh7"> {content.subscription && content.subscription.name}</p>
                  <SubPink />
                </div>
              </div>

              <div className=" display flexd dadplay">
                {myFile.data.url !== undefined ? (
                  <audio controls className="audio">
                    <source src={fixUrl(myFile.data.url)} type="audio/ogg" />
                    <source src={fixUrl(myFile.data.url)} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                ) : (
                  <div style={{ color: "red" }}>{myFile.message}</div>
                )}
              </div>

              {/* <div>
                    <Slider progress defaultValue={0} />
                    <div  className="podtime">
                      <p className="podtimer">{content.detail.duration}</p>
                      <p className="podtimer useredit">00:00</p>
                    </div>
                  </div> */}
              {/* 
                <ReactPlayer
                  //  light
                  url={myFile ? fixUrl(myFile.data.url) : ""}
                  playing={true}
                  // volume={0}
                  // style={styles}
                  controls
                  // muted
                  width="100%"
                  height="50%"
                /> */}
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }
}

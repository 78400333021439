import React, { Component } from "react";

// Components
import { NavLink } from "react-router-dom";
// import { Category } from '../../../api/category';
// import './style.css';
import { FlexboxGrid } from "rsuite";
import "react-owl-carousel2/src/owl.carousel.css";
import "rc-slider/assets/index.css";
import OwlCarousel from "react-owl-carousel2";
import img from "../../../assets/images/logo/avalogo.png";

class Box extends Component {
  componentDidMount() {}

  render() {
    const options = {
      items: 3,
      nav: false,
      rewind: false,
      autoplay: false,
      smartSpeed: 450,
      animateOut: "fadeOut",
      animateIn: "fadeIn",
      // autoWidth: true,
      center: true,
      loop: true,
      rtl: true,
      margin: 10,
      responsiveClass: true,
      responsive: {
        0: {
          items: 3,
          nav: false,
          rtl: true,
        },
        600: {
          items: 4,
          nav: false,
          rtl: true,
        },
        1000: {
          items: 5,
          nav: false,
          loop: false,
          rtl: true,
        },
      },
    };
    // console.log("props", props);

    return (
      <div className="small-slider">
        {this.props.category && this.props.category.length ? (
          <OwlCarousel options={options}>
            {this.props.category.map((item, key) => (
              <NavLink
                className="menu display Bshadow"
                to={`/main/categories/${item.id}/${item.name}`}
              >
                <FlexboxGrid.Item className="display flexd ">
                  <img src={item.picture} className="menuimg" alt="" />
                  <p className="menuti">{item.name}</p>
                </FlexboxGrid.Item>
              </NavLink>
            ))}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Box;

import React, { Component } from "react";

import Category from "../categories/box";
import Most from "../most/box";

import "./style.css";
import { FlexboxGrid, Col } from "rsuite";

// api
import * as most from "../../../api/most";

import { category } from "../../../api/category";
import Spinner from "../../../components/Loading";

class Content extends Component {
  state = {
    category: [],
    loading: false,
  };

  componentDidMount() {
    this.getCategories();
    this.getVisits();
    this.getMostVisits();
    this.getRecommended();
  }

  getCategories = async () => {
    this.setState({
      loading: true,
    });
    try {
      const {
        data: { data },
      } = await category();

      this.setState({ category: data });
    } catch (error) {
      console.error(error);
    }
    this.setState({
      loading: false,
    });
  };

  getVisits = async () => {
    const {
      data: { data },
    } = await most.Visit();

    this.setState({ visits: data });
  };

  getMostVisits = async () => {
    const {
      data: { data },
    } = await most.MostVisit();
    this.setState({ mostVisits: data });
  };

  getRecommended = async () => {
    const {
      data: { data },
    } = await most.Recommended();
    this.setState({ recommended: data });
  };
  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <>
          <div className="loading_apge">
            <div class=" lds-dual-ring"></div>
          </div>
        </>
      );
    }
    return (
      //categors
      <>
        <FlexboxGrid.Item
          componentClass={Col}
          className="display  dadmenu "
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <Category category={this.state.category} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          componentClass={Col}
          className="display flexd marginbot"
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <h4 className="h4">خوانده شده ها</h4>
          <FlexboxGrid.Item
            componentClass={Col}
            className=" daditem"
            style={{ overflow: " hidden" }}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <Most category={this.state.visits} />
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          componentClass={Col}
          className="display flexd marginbot "
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <h4 className="h4"> پیشنهادات ویژه</h4>

          <FlexboxGrid.Item
            componentClass={Col}
            className=" daditem end"
            style={{ overflow: " hidden" }}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <Most category={this.state.recommended} />
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          componentClass={Col}
          className="display flexd marginbot"
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <h4 className="h4"> پربازدید ترین ها</h4>
          <FlexboxGrid.Item
            // style={{ marginBottom: "40px", marginRight: "70px" }}
            componentClass={Col}
            className=" daditem end rt"
            style={{ overflow: " hidden" }}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <Most category={this.state.mostVisits} />
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>

        {/* <FlexboxGrid.Item
          componentClass={Col}
          className="display flexd  "
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <h4 className="h4"> پر طرفدار ترین ها</h4>
          <FlexboxGrid.Item
            componentClass={Col}
            className="display daditem"
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ marginBottom: "90px" }}
          >
            <Most category={this.state.category} />
          </FlexboxGrid.Item>
        </FlexboxGrid.Item> */}
      </>
    );
  }
}

export default Content;

import { APIClient } from "./_api";

function login(value) {
  return APIClient.post(`/login`, value);
}

function checkMobile(value) {
  return APIClient.post(`/checkMobile`, value);
}

function Education() {
  return APIClient.get(`/Education`);
}

function editProfile(value) {
  let bodyFormData = new FormData();
  for (let key in value) {
    bodyFormData.append(key, value[key]);
  }
  return APIClient.put(`User`, bodyFormData);
}

export { login, checkMobile, Education, editProfile };

import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";

// import Button from "../../components/button";

// import Buy from "../../assets/svg/ava/buy";

import { unsetAuthToken } from "../../api/_api";

import { FlexboxGrid, Col, Button, ButtonToolbar } from "rsuite";

export default class Profile extends Component {
  state = {
    name: "محمد کمال زاده",
    stateName: "",
    data: [],
    subscription: {},
    redirect: false,
  };

  submit = async (e) => {
    localStorage.removeItem("status", "");
    localStorage.removeItem("token", "");
    localStorage.removeItem("phoneNumber", "");
    unsetAuthToken();
    localStorage.removeItem("auth", "");
    window.location.replace("/login");
    // this.setState({ redirect: true });
  };

  // edit = e => {
  //   // this.setState({ name: e.target.value });
  //   SweetAlert({
  //     allowOutsideClick: true,
  //     width: "80vw",
  //     html: (
  //       <div className="edit_profile_div">
  //         <div className="edit_profile">ویرایش نام کاربری</div>
  //         <input
  //           onChange={e => {
  //             this.setState({ stateName: e.target.value });
  //           }}
  //         />
  //         <div>
  //           <Button gray className="profile_button" onClick={this.submit}>
  //             ثبت
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               Swal.close();
  //             }}
  //             gray
  //             className="profile_button"
  //           >
  //             انصراف
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   });
  // };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="pagerun">
        <FlexboxGrid.Item
          componentClass={Col}
          className="play display  "
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <FlexboxGrid.Item
            componentClass={Col}
            className=" display   buyit flexd index"
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <div className=" display flexd   buyTitle">
              <p>خروج از حساب کاربری</p>
            </div>
            <div
              // style={{
              //   display: "flex",
              //   justifyContent: "center",
              //   textAlign: "center",
              // }}
              className="marginbot log display"
            >
              <div>آیا می خواهید از حساب کاربری خود خارج شوید؟</div>
            </div>
            <ButtonToolbar className="marginbot marginbottom">
              <Button onClick={this.submit} className="buyButton">
                بله
              </Button>

              <Button
                onClick={() => window.history.back()}
                className="buyButton buyborder "
              >
                خیر
              </Button>
            </ButtonToolbar>
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
      </div>
    );
  }
}

import React, { Component } from "react"
import Loading from "../../../assets/images/ava/undraw_loading_frh4.png"
import { FlexboxGrid, Col, Form, FormGroup, ButtonToolbar, Button } from "rsuite"

// API
import * as Acount from "../../../api/account"

// Components
import * as Yup from "yup"

export default class Waiting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: ""
    }
  }
  ValidationSchema = this.props.nameField
    ? Yup.object().shape({
        password: Yup.string().required().min(6).max(6),
        name: Yup.string().required()
      })
    : Yup.object().shape({
        password: Yup.string().required().min(6).max(6)
      })
  initialValues = {
    phoneNumber: null,
    name: null,
    password: null
  }

  resetCode = async () => {
    try {
      await Acount.login(this.props.data)
    } catch (error) {
      console.error(error)
      console.error(error.response)
    }
  }

  handleSubmit = async () => {
    try {
      const value = {
        phoneNumber: this.props.data.phoneNumber
          ? this.props.data.phoneNumber
          : localStorage.getItem("phoneNumber")
      }
      let data = await Acount.checkMobile(value)
      if (data.status === 200 || this.props.status === "OK") {
        this.props.goNext(3)
        await this.setState({
          verify: true,
          phoneNumber: this.props.data.phoneNumber
            ? this.props.data.phoneNumber
            : localStorage.getItem("phoneNumber"),
          password: null
        })
        localStorage.setItem("status", data.statusText)
        localStorage.setItem("phoneNumber", this.state.initialValue.phoneNumber)
      }

      // let data = {};
      // if (this.props.nameField) {
      //   data = await Acount.register(value);
      // } else {
      //   data = await Acount.login(value);
      // }
      this.setState({ error: "" })
      // localStorage.setItem("token", data.data.token);
      // localStorage.setItem("name", data.data.name);
      // setAuthToken(data.data.token);

      // this.props.redirect();
    } catch (error) {
      console.error(error)
      console.error(error.response)
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length
      ) {
        this.setState({
          error: error.response.data.errors[0].detail
        })
      }
    }
    // setSubmitting(false);
    // resetForm();
  }

  backPage = () => {
    this.props.goNext(1)
    localStorage.setItem("status", "")
    localStorage.setItem("phoneNumber", "")
  }

  render() {
    if (this.props.status === "OK") {
      this.props.goNext(3)
    }
    return (
      <div className="backgroundColor">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="background "
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <Form className="display flexj flexd form2">
              <FormGroup className="intro">
                <FormGroup className="info ">
                  <p className="color h5 padding resize"> انتظار برای تایید مدیر</p>

                  <p className="colorGray p padding resize">
                    لطفا صبر کنید تا مدیر شماره شما را تایید و یک کد تایید برای شما بفرستد
                  </p>
                </FormGroup>
                <FormGroup className="margint">
                  <img src={Loading} className="loader" alt="" />
                </FormGroup>
              </FormGroup>
              <FormGroup className="display fgap  align">
                <div>
                  <p className="back">
                    شماره اشتباه است ؟{" "}
                    <a
                      onClick={this.backPage}
                      style={{ cursor: "pointer" }}
                      className="colorpink"
                      href
                    >
                      تغییر دهید
                    </a>
                  </p>
                  <ButtonToolbar>
                    <Button onClick={this.handleSubmit} className="button inputSize">
                      {" "}
                      تلاش مجدد
                    </Button>
                  </ButtonToolbar>
                </div>
              </FormGroup>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    )
  }
}

import React, { Component } from "react";

import Header from "../../components/Layouts/MainHeader";
import { FlexboxGrid, Col } from "rsuite";

import { File } from "../../api/content";
export default class choose extends Component {
  state = {
    playing: false,
    myFile: { url: "" },
    content: {
      id: "",
      name: "",
      picture: "",
      contentType: "",
      detail: {
        pages: "",
        writer: "",
        translator: "",
        publisher: "",
        edition: "",
        description: "",
      },
    },
    id: "",
    activeContent: false,
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.getFile(id);
  }

  getFile = async (id) => {
    this.setState({ loading: true });
    try {
      let myFile = await File(this.props.match.params.id);
      console.log("myFile", myFile.data.data);

      await this.setState({
        myFile: myFile.data.data,
      });
      // console.log("category",this.state.content);
    } catch (error) {
      console.error(error);
    }
    this.setState({ loading: false });
  };

  player = () => {
    this.setState({ playing: !this.state.playing });
  };

  render() {
    const { myFile, loading } = this.state;
    if (loading) {
      return (
        <>
          <div className="loading_apge">
            <div class="lds-dual-ring"></div>
          </div>
        </>
      );
    }
    return (
      <div className="backgroundColor">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="user"
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            style={{ padding: "0px" }}
            xs={24}
          >
            <Header
              pageTitle={this.props.match.params.title}
              back={true}
              search={true}
              bg={true}
              black={true}
              edit={false}
            />
            <FlexboxGrid.Item
              className=" userdetail none display flexd start form3"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <FlexboxGrid.Item
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="titleBook"
              >
                {" "}
                <iframe
                  src={`https://docs.google.com/viewerng/viewer?url=http://dl.ava-edugroup.ir/${myFile.url}&embedded=true`}
                  frameborder="0"
                  height="100%"
                  width="100%"
                  title="pdf"
                ></iframe>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

import React from "react";
import { NavLink } from "react-router-dom";

import MenuLayout from "../../components/Layouts/Menu_layout";
import Slider from "../../components/Slider";
import Content from "./content/index";
import About from "../../assets/svg/about";
import Logo from "../../assets/images/logo/avalogo.png";
import { FlexboxGrid, Col } from "rsuite";

function home() {
  return (
    <>
      <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
        <div className="  mainlogo">
          <img src={Logo} className="logax" alt="" />
          <h4 className="logoname">گروه آموزشی آوا</h4>
        </div>
        <div className="mainAbout display">
          <NavLink to={`/main/aboutUs`} class="colorpink a">
            <About />
          </NavLink>
        </div>
      </FlexboxGrid.Item>
      <Slider />
      <Content />
    </>
  );
}

export default MenuLayout(home);

import React from "react";

export default () => (
  <svg width="55" height="59" viewBox="0 0 55 59">
    <g
      id="subscription_1_"
      data-name="subscription (1)"
      transform="translate(-11.667)"
    >
      <path
        id="Path_268"
        data-name="Path 268"
        d="M65.823,2.872H54.342V.864A.854.854,0,0,0,53.5,0H24.434a.854.854,0,0,0-.844.864V2.872H12.511a.863.863,0,0,0-.844.864V26.415a.854.854,0,0,0,.844.864H23.59v6.31a.854.854,0,0,0,.844.864h2.576a3.147,3.147,0,0,0-.32,1.072l-1.11,9.839a7.036,7.036,0,0,0-.044.832,7.224,7.224,0,0,0,2.7,5.451l2.81,2.477v4.011a.854.854,0,0,0,.844.864h2.6a.864.864,0,0,0,0-1.729H32.732V53.728a.873.873,0,0,0-.293-.655l-2.772-2.444a5.931,5.931,0,0,1-2.441-4.446,5.25,5.25,0,0,1,.033-.62l1.11-9.839A1.353,1.353,0,0,1,29.7,34.508a1.384,1.384,0,0,1,1.006.467,1.42,1.42,0,0,1,.336.906v7.286a.844.844,0,1,0,1.688,0V19.749a1.342,1.342,0,1,1,2.683-.007s0,0,0,.006,0,.009,0,.013V30.688a.857.857,0,1,0,1.7.011c0-.188,0-.74,0-.883.151-1.758,3.029-1.716,3.029.09v1.309a.844.844,0,0,0,1.688,0,1.361,1.361,0,0,1,1.344-1.376c.839,0,1.689.372,1.689,1.376v.851a.844.844,0,0,0,1.688.015,1.267,1.267,0,0,1,.043-.353,1.365,1.365,0,0,1,2.639-.007l1.042,4.107a17.794,17.794,0,0,1-.088,9.06l-2.36,8.605a.88.88,0,0,0-.031.234v3.544H38.526a.864.864,0,0,0,0,1.729H48.64a.854.854,0,0,0,.844-.864V53.847l2.328-8.491a19.564,19.564,0,0,0,.1-9.962l-.239-.942H53.5a.854.854,0,0,0,.844-.864V8.106H64.979V25.55H57.466a.864.864,0,0,0,0,1.729h8.357a.854.854,0,0,0,.844-.864V3.736h0a.855.855,0,0,0-.844-.864ZM13.355,4.6H23.59V6.377H13.355ZM25.279,1.729H52.653V6.377H25.279ZM13.355,25.55V8.106H23.59V25.55Zm37.876,7.174-.365-1.438a3.063,3.063,0,0,0-4.774-1.716,3.335,3.335,0,0,0-4.543-.976A3.3,3.3,0,0,0,37.1,27.129V20.613h7.679a.829.829,0,0,1,.817.838v1.66a.828.828,0,0,1-.817.837h-4.62a.864.864,0,0,0,0,1.729h4.62a2.539,2.539,0,0,0,2.505-2.565v-1.66a2.539,2.539,0,0,0-2.505-2.566h-7.8a3.022,3.022,0,0,0-5.939.865V32.724H25.279V8.106H52.653V32.725Zm3.11-26.348V4.6H64.978V6.377Z"
        transform="translate(0)"
        fill="white"
      />
      <path
        id="Path_269"
        data-name="Path 269"
        d="M214.2,28.036H204.1a.871.871,0,0,0,0,1.742h10.1a.871.871,0,0,0,0-1.742Z"
        transform="translate(-170.184 -24.781)"
        fill="white"
      />
      <path
        id="Path_270"
        data-name="Path 270"
        d="M164.35,84.734h-6.876a.871.871,0,0,0-.871.871v3.977a.871.871,0,0,0,.871.871h6.876a.871.871,0,0,0,.871-.871V85.6A.871.871,0,0,0,164.35,84.734Zm-.871,3.977h-5.134V86.476h5.134Z"
        transform="translate(-128.716 -74.978)"
        fill="white"
      />
      <path
        id="Path_271"
        data-name="Path 271"
        d="M406.728,111.469H411.9a.871.871,0,1,0,0-1.742h-5.173a.871.871,0,1,0,0,1.742Z"
        transform="translate(-350.16 -97.086)"
        fill="white"
      />
      <path
        id="Path_272"
        data-name="Path 272"
        d="M411.9,143.733a.871.871,0,1,0,0-1.742h-5.173a.871.871,0,1,0,0,1.742Z"
        transform="translate(-350.16 -125.632)"
        fill="white"
      />
      <path
        id="Path_273"
        data-name="Path 273"
        d="M42.81,111.469h5.173a.871.871,0,1,0,0-1.742H42.81a.871.871,0,0,0,0,1.742Z"
        transform="translate(-26.757 -97.086)"
        fill="white"
      />
      <path
        id="Path_274"
        data-name="Path 274"
        d="M47.983,141.991H42.81a.871.871,0,1,0,0,1.742h5.173a.871.871,0,1,0,0-1.742Z"
        transform="translate(-26.757 -125.632)"
        fill="white"
      />
      <path
        id="Path_275"
        data-name="Path 275"
        d="M263.2,101.861h-9.238a.871.871,0,1,0,0,1.742H263.2a.871.871,0,1,0,0-1.742Z"
        transform="translate(-214.461 -90.126)"
        fill="white"
      />
    </g>
  </svg>
);

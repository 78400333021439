import React from "react";

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <defs>
      <clipPath id="clip-path">
        <rect width="18" height="18" fill="none" />
      </clipPath>
    </defs>
    <g
      id="Backward_arrow"
      data-name="Backward arrow"
      clip-path="url(#clip-path)"
    >
      <path
        id="Path_10"
        data-name="Path 10"
        d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
        transform="translate(16 16) rotate(180)"
        fill="#252934"
      />
    </g>
  </svg>
);

import React from "react";

export default () => (
  <svg width="46" height="42.79" viewBox="0 0 46 42.79">
    <path
      id="Combined_Shape"
      data-name="Combined Shape"
      d="M9.883,42.79a2.71,2.71,0,0,1-2.694-2.72V33.129L.254,18.123a2.732,2.732,0,0,1,1.3-3.614L13.516,8.881A.891.891,0,0,1,13.9,8.8a.908.908,0,0,1,.381,1.728L2.317,16.152a.912.912,0,0,0-.435,1.205l.911,1.971L7.21,17.251a2.708,2.708,0,0,1,2.674-2.383h2.389L34.712,4.31,33.8,2.338a.9.9,0,0,0-.507-.469.88.88,0,0,0-.687.031l-11,5.176a.884.884,0,0,1-.38.085.9.9,0,0,1-.814-.523.912.912,0,0,1,.435-1.205l11-5.176A2.654,2.654,0,0,1,32.985,0a2.7,2.7,0,0,1,2.445,1.572l6.144,13.3H43.3a2.711,2.711,0,0,1,2.7,2.72V40.071a2.711,2.711,0,0,1-2.7,2.72Zm-.9-25.2V40.071a.9.9,0,0,0,.9.907H43.3a.9.9,0,0,0,.9-.907V17.588a.9.9,0,0,0-.9-.906H9.883A.9.9,0,0,0,8.985,17.588ZM5.756,25.737l1.433,3.1V25.063Zm-2.2-4.766L5,24.094l2.193-1.032v-3.8Zm25.3-6.1H39.591l-1.917-4.149Zm-12.329,0H24.6L36.914,9.076,35.472,5.954Zm-4.3,22.746a.863.863,0,1,1,0-1.725H24.165a.863.863,0,1,1,0,1.725Zm24.7-4.831a.863.863,0,1,1,0-1.725h3.728a.863.863,0,1,1,0,1.725Zm-8.239,0a.863.863,0,1,1,0-1.725h3.728a.863.863,0,1,1,0,1.725Zm-8.239,0a.863.863,0,1,1,0-1.725h3.728a.863.863,0,1,1,0,1.725Zm-7.9,0a.863.863,0,1,1,0-1.725h3.728a.863.863,0,1,1,0,1.725ZM34.6,27.607a2.689,2.689,0,0,1-2.67-2.7V22.025a2.689,2.689,0,0,1,2.67-2.7h4.271a2.688,2.688,0,0,1,2.67,2.7v2.881a2.689,2.689,0,0,1-2.67,2.7Zm3.026-1.8h1.245a.9.9,0,0,0,.89-.9V22.025a.9.9,0,0,0-.89-.9H37.621Zm-3.916-3.781v2.881a.9.9,0,0,0,.89.9h1.245V21.126H34.6A.9.9,0,0,0,33.706,22.025ZM18.193,26.586a4.141,4.141,0,1,1,0-6.24,4.141,4.141,0,1,1,0,6.24ZM19.267,21.8a4.124,4.124,0,0,1,0,3.323,2.34,2.34,0,1,0,0-3.323Zm-6.136,1.662a2.342,2.342,0,1,0,2.343-2.34A2.344,2.344,0,0,0,13.131,23.466ZM17.072,8.719a.855.855,0,1,1,.607.253A.868.868,0,0,1,17.072,8.719Z"
      fill="#fe2e62"
    />
  </svg>
);

import React, { Component } from "react";

// Components
import { NavLink } from "react-router-dom";

// import { Category } from '../../../api/category';

// import './style.css';
// import { FlexboxGrid } from "rsuite";
import "react-owl-carousel2/src/owl.carousel.css";
import "rc-slider/assets/index.css";
import OwlCarousel from "react-owl-carousel2";
import Podcast from "../../../assets/svg/ava/podcast";
import Book from "../../../assets/svg/ava/book";
import Video from "../../../assets/svg/ava/video";
import Subgray from "../../../assets/svg/ava/subgray";
class Box extends Component {
  componentDidMount() {}

  render() {
    const options = {
      items: 3,
      nav: false,
      rewind: false,
      autoplay: false,
      smartSpeed: 450,
      animateOut: "fadeOut",
      animateIn: "fadeIn",
      center: false,
      touchDrag: true,
      loop: false,
      rtl: true,
      // margin: 20,
      responsiveClass: true,
      responsive: {
        0: {
          items: 3,
          nav: false,
          rtl: true,
        },
        600: {
          items: 4,
          nav: false,
          rtl: true,
        },
        1000: {
          items: 5,
          nav: false,
          loop: false,
          rtl: true,
        },
      },
    };
    const { props } = this;
    // console.log("props", props);

    return (
      <div className="small-slider  ">
        <OwlCarousel options={options} className="rightanime ">
          {this.props.category && this.props.category.length
            ? this.props.category.map((item, key) => {
                return item.contentType === "Podcast" ? (
                  <NavLink
                    className=" display flexd"
                    // onClick={()=>this.handleClick(item.id)}
                    to={`/main/contentPodcast/${item.id}/${item.name}`}
                  >
                    {/* <div className="category Bshadow display flexd  "> */}
                    <img src={item.picture} className="item Bshadow " alt="" />
                    <div className="display">
                      <Podcast />
                      <p className="marginr pitem">{item.name}</p>
                    </div>
                    {/* <div className="display   ">
                      <Subgray />
                      <p className="marginr subgray"> اشتراک کمیاب</p>
                    </div> */}
                    {/* </div> */}
                  </NavLink>
                ) : item.contentType === "Book" ? (
                  <NavLink
                    className=" display flexd"
                    // onClick={()=>this.handleClick(item.id)}
                    to={`/main/contentBook/${item.id}/${item.name}`}
                  >
                    {/* <div className="category Bshadow display flexd "> */}
                    <img src={item.picture} className="item Bshadow" alt="" />
                    <div className="display">
                      <Book />
                      <p className="marginr pitem">{item.name}</p>
                    </div>

                    {/* <div className="display   ">
                      <Subgray />
                      <p className="marginr subgray">اشتراک کمیاب</p>
                    </div> */}
                    {/* </div> */}
                  </NavLink>
                ) : (
                  <NavLink
                    className=" display flexd"
                    // onClick={()=>this.handleClick(item.id)}
                    to={`/main/contentVideo/${item.id}/${item.name}`}
                  >
                    {/* <div className="category Bshadow display flexd "> */}
                    <img src={item.picture} className="item Bshadow" alt="" />
                    <div className="display ">
                      <Video />
                      <p className="marginr pitem">{item.name}</p>
                    </div>
                    {/* <div className="display  ">
                      <Subgray />
                      <p className="marginr subgray">اشتراک کمیاب</p>
                    </div> */}
                    {/* </div> */}
                  </NavLink>
                );
              })
            : "  "}
        </OwlCarousel>
      </div>
    );
  }
}

export default Box;

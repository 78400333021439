import React from "react";

export default () => (
  <svg width="10.981" height="9.134" viewBox="0 0 10.981 9.134">
    <g id="open-book" transform="translate(-2 -5.69)">
      <path
        id="Path_1285"
        data-name="Path 1285"
        d="M9.374,5.69h2.42v6.254H8.915l-.152.015H8.671l-.162.027L8.419,12c-.065.015-.13.035-.195.055l-.057.017a1.729,1.729,0,0,0-.249.1l-.057.035a1.352,1.352,0,0,0-.132.082V7.324A1.636,1.636,0,0,1,9.374,5.69Zm-6.192,0H5.6A1.636,1.636,0,0,1,7.239,7.324v5a1.347,1.347,0,0,0-.13-.082l-.06-.037a1.772,1.772,0,0,0-.237-.1l-.072-.015c-.062-.02-.127-.04-.19-.055L6.468,12l-.16-.027H6.213l-.15-.015H3.182Zm9.794,7.486a.187.187,0,0,1-.187.185H9.683c-1.427,0-2.008.369-2.008,1.277a.186.186,0,0,1-.372,0c0-.908-.581-1.277-2-1.277H2.187A.187.187,0,0,1,2,13.176V6.483A.187.187,0,0,1,2.187,6.3h.5v5.9a.249.249,0,0,0,.249.249H5.6c1.355,0,1.634.4,1.634,1a.249.249,0,1,0,.5,0c0-.6.277-1,1.634-1h2.677A.249.249,0,0,0,12.3,12.2V6.3h.5a.187.187,0,0,1,.187.187Z"
        transform="translate(0 0)"
        fill="#c1c1ca"
      />
    </g>
  </svg>
);

import React, { Component } from "react";

// Components
import Header from "../../components/Layouts/MainHeader";
// import Button from '../../components/button';
import { NavLink } from "react-router-dom";
import NoImage from "../../assets/images/avatars/empty2.png";
// import { showByRatio } from '../../api/image';
// import Star from '../../assets/svg/star';
import "./style.css";

import { getTotal } from "../../utils/getTotal";

import { products } from "../../api/products/products";
import { addProductToCart } from "../../api/cart/cart";
class Products extends Component {
  state = {
    products: [],
    cart: [],
    thisProduct: {},
  };
  componentDidMount() {
    this.getProducts();
    this.getProductCart();
  }
  getProducts = async () => {
    const { data } = await products(this.props.match.params.id);
    this.setState({ products: data.products });
  };

  getProductCart = async () => {
    fetch("https://zoodmarket-app.ir/api/user/v1/cart.php", {
      headers: { token: localStorage.getItem("token") },
    }).then((res) => {
      res.json().then((res) => {
        this.setState({ cart: res.cart, total: getTotal(res.cart) });
      });
    });
  };
  timeout;

  add = async (product) => {
    clearTimeout(this.timeout);
    let value = {
      count: Number(product.count) + 1,
      id: product.id,
    };
    let cartCopy = [...this.state.cart];
    let index = cartCopy.findIndex((x) => x.id === product.id);
    if (index > -1) {
      await ++cartCopy[index].count;
    } else {
      cartCopy.push({ id: product.id, count: 1 });
    }
    this.setState({ cart: cartCopy });

    this.timeout = setTimeout(async () => {
      await addProductToCart(value);
      this.getProductCart();
    }, 1000);
  };

  minus = async (product) => {
    clearTimeout(this.timeout);
    let value;
    if (product.count > 0) {
      value = {
        count: Number(product.count) - 1,
        id: product.id,
      };
    } else {
      value = {
        count: 0,
        id: product.id,
      };
    }
    let cartCopy = [...this.state.cart];
    let index = cartCopy.findIndex((x) => x.id === product.id);
    if (index > -1) {
      await --cartCopy[index].count;
    } else {
      cartCopy.push({ id: product.id, count: 1 });
    }
    this.setState({ cart: cartCopy });
    this.timeout = setTimeout(async () => {
      await addProductToCart(value);
      this.getProductCart();
    }, 1000);
  };

  Setbutton = ({ product }) => {
    let index = this.state.cart.findIndex((x) => x.id === product.id);
    let thisProduct = this.state.cart.find((x) => x.id === product.id);
    if (index > -1) {
      return (
        <div className="cart-count-product">
          <div
            onClick={() => this.add(thisProduct)}
            className="mdi mdi-plus close_modal icon"
          ></div>
          <div className="number">{thisProduct.count}</div>
          <div
            onClick={() => this.minus(thisProduct)}
            className="mdi mdi-minus close_modal icon"
          ></div>
        </div>
      );
    } else {
      return (
        <button
          onClick={() => this.firstAdd(product)}
          className="product_button"
        >
          افزودن به سبد خرید
        </button>
      );
    }
  };

  firstAdd = async (item) => {
    let value;
    value = {
      id: item.id,
      count: 1,
    };
    await addProductToCart(value);
    await this.getProductCart();
    // console.log('cart', this.state.cart);

    let thisProduct = this.state.cart.find((x) => x.id === item.id);
    this.setState({ thisProduct });

    // console.log('thisProduct', thisProduct);

    // console.log('item', item);
  };

  render() {
    const { props } = this;
    return (
      <>
        <div className="HeaderFather">
          <Header
            pageTitle={props.match.params.title}
            back={true}
            search={true}
            bg={true}
            edit={false}
            cart={this.state.cart}
          />
        </div>

        <div className="row-product">
          <div className="row-product-div">
            {this.state.products.length ? (
              this.state.products.map((product) => {
                return (
                  <>
                    <div className="product_place_box">
                      <NavLink
                        to={`/main/productDetails/${product.id}/${product.name}`}
                      >
                        <div className="content">
                          <div className="image_mask">
                            <img
                              src={product.image1 ? product.image1 : NoImage}
                              alt=""
                            />
                          </div>
                          <div className="title">{product && product.name}</div>
                          <div className="price">
                            {product.price2 === product.price3 ? (
                              product.price2
                            ) : (
                              <div className="both-price">
                                <span className="price2">
                                  {" "}
                                  {product.price2}
                                </span>

                                <span className="price"> {product.price3}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </NavLink>
                      <this.Setbutton product={product} />

                      {/* {console.log(
                        'find',
                        this.state.cart.findIndex(x => x.id === product.id)
                      )}
                      {this.state.cart.findIndex(x => x.id === product.id) ? (
                        <div>Fuck</div>
                      ) : (
                        <button
                          onClick={() => this.add(product)}
                          className="product_button"
                        >
                          افزودن به سبد خرید
                        </button>
                      )} */}
                    </div>
                  </>
                );
              })
            ) : (
              <div className="noProduct">
                محصولی برای این دسته بندی ثبت نشده است
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Products;

import React from "react";

export default () => (
  <svg width="25" height="25" viewBox="0 0 25 25">
    <g id="Group_171" data-name="Group 171" transform="translate(-19.5 -53)">
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(19.5 53)"
        fill="none"
      />
      <g id="edit" transform="translate(24.503 59)">
        <path
          id="Path"
          d="M6.5,1H.5a.5.5,0,0,1,0-1h6a.5.5,0,0,1,0,1Z"
          transform="translate(7 13)"
          fill="#fff"
        />
        <path
          id="Shape"
          d="M.541,14a.592.592,0,0,1-.388-.145.494.494,0,0,1-.139-.471l1-4a.489.489,0,0,1,.14-.23l9-9a.48.48,0,0,1,.7,0l3,3a.48.48,0,0,1,0,.7l-9,9a.489.489,0,0,1-.23.14l-4,1A.59.59,0,0,1,.541,14Zm1.232-3.516-.58,2.33,2.33-.58Zm8.73-9.27L2.213,9.5,4.5,11.792l8.29-8.29Z"
          transform="translate(-0.003 -0.002)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import Header from "../../components/Layouts/MainHeader";
// import Button from "../../components/button";
// import Swal from "../../components/Alart-Sweet/sweet-alert";
import Wallet from "../../assets/svg/ava/wallet";
import Menu from "../../components/Layouts/Main_menu";
import { FlexboxGrid, Col, Button, ButtonToolbar, Modal } from "rsuite";
import Card from "../../assets/svg/ava/Card";

import { Subscription, SubscriptionById } from "../../api/subscription";
import { getMe } from "../../api/me";
import { Buy } from "../../api/order";

export default class Profile extends Component {
  state = {
    name: "محمد کمال زاده",
    stateName: "",
    data: [],
    show: false,
    subscription: {},
    // disabled: false,
  };

  componentDidMount() {
    this.getSubscription();
    this.getMe();
  }

  getMe = async () => {
    const { data } = await getMe();
    await this.setState({ myData: data.data });
    // if (data.data.subscription) {
    //   this.setState({ disabled: true });
    // } else {
    //   this.setState({ disabled: false });
    // }
  };

  getSubscriptionById = async (id) => {
    this.setState({ loading: true });
    try {
      const { data } = await SubscriptionById(id);
      // console.log(data);
      this.setState({ subscription: data.data });
    } catch (error) {}
    this.setState({ loading: false });
  };

  getSubscription = async () => {
    this.setState({ loading: true });

    try {
      const { data } = await Subscription();
      // console.log(data.data);

      this.setState({ data: data.data });
    } catch (error) {}
    this.setState({ loading: false });
  };

  // submit = (e) => {

  //   this.setState({ name: this.state.stateName });
  //   Swal.close();
  // };

  close = () => {
    this.setState({ show: false });
  };
  open = (id) => {
    this.setState({ show: true });
    this.getSubscriptionById(id);
  };

  // edit = e => {
  //   // this.setState({ name: e.target.value });
  //   SweetAlert({
  //     allowOutsideClick: true,
  //     width: "80vw",
  //     html: (
  //       <div className="edit_profile_div">
  //         <div className="edit_profile">ویرایش نام کاربری</div>
  //         <input
  //           onChange={e => {
  //             this.setState({ stateName: e.target.value });
  //           }}
  //         />
  //         <div>
  //           <Button gray className="profile_button" onClick={this.submit}>
  //             ثبت
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               Swal.close();
  //             }}
  //             gray
  //             className="profile_button"
  //           >
  //             انصراف
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   });
  // };

  submit = async (e) => {
    try {
      let value = {
        subscriptionId: this.state.subscription && this.state.subscription.id,
        device: "Web",
      };
      // console.log(value);
      const { data } = await Buy(value);

      if (data && data.data && data.data.payLink) {
        window.location.href = data.data.payLink;
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <>
        <div className="backgroundColor">
          <FlexboxGrid>
            <FlexboxGrid.Item
              className="user"
              componentClass={Col}
              lg={24}
              md={24}
              style={{ padding: "0" }}
              sm={24}
              xs={24}
            >
              <Header
                pageTitle={"خرید اشتراک"}
                back={true}
                search={true}
                bg={true}
                edit={false}
              />

              <Modal show={this.state.show} onHide={this.close}>
                <div className="pagerun">
                  <FlexboxGrid.Item
                    componentClass={Col}
                    className=" display   buyit flexd index"
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <div className=" display flexd   buyTitle">
                      <p style={{ marginTop: "20px" }}>خرید اشتراک</p>
                      <div className="marginbot">
                        <Card />
                      </div>
                    </div>
                    <div className="marginbot matn">
                      <div className="marginbot">
                        <p className="inlineB bold">نام اشتراک</p>
                        <p className="inlineB useredit mt">
                          {" "}
                          {this.state.subscription.name}
                        </p>
                      </div>
                      <div className="marginbot">
                        <p className="inlineB bold"> مدت زمان</p>
                        <p className="inlineB useredit mt">
                          {" "}
                          {this.state.subscription.duration} روز
                        </p>
                      </div>
                      <div className="marginbot">
                        <p className="inlineB bold"> قیمت</p>
                        <p className="inlineB useredit mt">
                          {" "}
                          {this.state.subscription.price} ریال
                        </p>
                      </div>
                    </div>
                    {this.state.loading ? (
                      <div className="loading_apge">
                        <div class="lds-dual-ring"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    <ButtonToolbar className="marginbot marginbottom">
                      {this.state.myData &&
                      this.state.myData.subscription.priority <
                        this.state.subscription.priority ? (
                        <>
                          <button
                            type="button"
                            onClick={this.submit}
                            className="buyButton"
                          >
                            خرید
                          </button>
                          <Button
                            onClick={() => this.close()}
                            className="buyButton buyborder "
                          >
                            انصراف
                          </Button>
                        </>
                      ) : (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            style={{ marginBottom: "10px", width: "100%" }}
                            className="buyButton"
                          >
                            اشتراک شما بالاتر میباشد
                          </Button>
                          <Button
                            style={{ width: "100%", marginBottom: "10px" }}
                            onClick={() => this.close()}
                            className="buyButton buyborder "
                          >
                            انصراف
                          </Button>
                        </div>
                      )}
                    </ButtonToolbar>
                  </FlexboxGrid.Item>
                </div>
                {/* <Modal.Header>
              <Modal.Title>{this.state.subscription.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <div>
                  hello
                </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} appearance="primary">
              Ok
            </Button>
            <Button onClick={this.close} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer> */}
              </Modal>

              <FlexboxGrid.Item
                className="userdetail display flexd  form3"
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <FlexboxGrid.Item
                  className="display flexd"
                  componentClass={Col}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <FlexboxGrid.Item
                    className="wrap center margint history "
                    componentClass={Col}
                    lg={24}
                    md={24}
                    sm={24}
                    // style={{ marginRight: "95px" }}
                    xs={24}
                  >
                    {/* وقتی اکتیو شد کلاسش میشه */}
                    {/* soldactive */}
                    {this.state.loading ? (
                      <div className="loading_apge">
                        <div class="lds-dual-ring"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.data &&
                      this.state.data.map((x) => {
                        return (
                          <button
                            onClick={() => this.open(x.id)}
                            disabled={this.state.disabled}
                            className="newbut"
                          >
                            <div>
                              <div
                                style={{
                                  backgroundColor: x.color,
                                  color: "white",
                                }}
                                className="sold shadow display rightanime"
                              >
                                <div>
                                  <p className="bold"> {x.name}</p>
                                  <p>{x.duration} </p>

                                  <p>{x.price} ریال</p>
                                </div>
                                <div className="wallet">
                                  <Wallet />
                                </div>
                              </div>
                            </div>
                          </button>
                        );
                      })}
                  </FlexboxGrid.Item>
                </FlexboxGrid.Item>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Menu />
        </div>
      </>
    );
  }
}

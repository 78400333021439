import React from "react";

export default () => (
  <svg width="23.998" height="19.999" viewBox="0 0 23.998 19.999">
    <path
      id="telegram"
      d="M9.417,15.181l-.4,5.584a1.388,1.388,0,0,0,1.109-.537l2.663-2.545,5.518,4.041c1.012.564,1.725.267,2-.931L23.93,3.821h0c.321-1.5-.541-2.081-1.527-1.714L1.114,10.257C-.339,10.821-.317,11.631.867,12L6.31,13.691,18.953,5.78c.595-.394,1.136-.176.691.218Z"
      transform="translate(0 -2)"
      fill="#fe2e62"
    />
  </svg>
);

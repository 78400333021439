import React from "react";

export default () => (
  <svg width="25" height="25" viewBox="0 0 25 25">
    <g id="Group_173" data-name="Group 173" transform="translate(-333.5 -53)">
      <g id="Group" transform="translate(349.502 72.999) rotate(180)">
        <path
          id="Path"
          d="M7.707,12.293a1,1,0,1,1-1.414,1.414l-6-6a1,1,0,0,1-.03-1.383l6.03-6A1,1,0,0,1,7.707.263a1,1,0,0,1,0,1.413L2.384,6.97Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="12.5"
        cy="12.5"
        r="12.5"
        transform="translate(333.5 53)"
        fill="none"
      />
    </g>
  </svg>
);

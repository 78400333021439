import React from "react";

export default () => (
  <svg width="26.299" height="24.413" viewBox="0 0 26.299 24.413">
    <g id="Group_21" data-name="Group 21" transform="translate(-175.5 -741.72)">
      <path
        id="Path"
        d="M.251,2.44a1.231,1.231,0,0,1,0-2,2.928,2.928,0,0,1,3,0l7,6a1.231,1.231,0,0,1,0,2,2.928,2.928,0,0,1-3,0Z"
        transform="translate(191.035 757.28)"
        fill="#252934"
      />
      <path
        id="Shape"
        d="M11.04,20a11.549,11.549,0,0,1-7.855-2.953A9.506,9.506,0,0,1,0,10,9.505,9.505,0,0,1,3.186,2.953,11.55,11.55,0,0,1,11.04,0a13.3,13.3,0,0,1,8.314,2.953A9.174,9.174,0,0,1,23,10a9.175,9.175,0,0,1-3.646,7.047A13.3,13.3,0,0,1,11.04,20Zm0-17C6.775,3,3.68,5.944,3.68,10s3.095,7,7.359,7a9.058,9.058,0,0,0,5.807-2.038A6.448,6.448,0,0,0,19.32,10a6.449,6.449,0,0,0-2.473-4.962A9.058,9.058,0,0,0,11.04,3Z"
        transform="translate(175.5 741.72)"
        fill="#252934"
      />
    </g>
  </svg>
);

import { APIClient } from './_api';

function category() {
  return APIClient.get(`/Category`);
}

function CategoryByParent(id) {
  return APIClient.get(`/CategoryByParent/${id}`);
}

// function verifyOTP(value) {
//   return APIClient.post(`/sms.php`, value);
// }

export { category,CategoryByParent };

import React from "react";

export default () => (
  <svg width="28px" height="24px">
    <path
      id="icons8-home"
      d="M25.182,26.1H17.545V18.481H12.455V26.1H4.818V14.671H1L15,2.1,29,14.671H25.182Z"
      transform="translate(-1 -2.1)"
      fill="black"
    />
  </svg>
);

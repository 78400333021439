import React, { Component } from "react";

// import Button from "../../components/button";

// import Buy from "../../assets/svg/ava/buy";
import Card from "../../assets/svg/ava/Card";

import { Buy } from "../../api/order";
import { SubscriptionById } from "../../api/subscription";
import { getMe } from "../../api/me";

import { FlexboxGrid, Col, Button, ButtonToolbar } from "rsuite";

export default class Profile extends Component {
  state = {
    name: "محمد کمال زاده",
    stateName: "",
    data: [],
    location: window.location.href,
    me: {
      subscription: {},
    },
    subscription: {},
  };
  componentDidMount() {
    this.getSubscriptionById();
    this.getMe();
  }

  getMe = async () => {
    const { data } = await getMe();

    // console.log(data);
    this.setState({ me: data.data });
  };

  getSubscriptionById = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await SubscriptionById(this.props.match.params.id);
      // console.log(data);
      this.setState({ subscription: data.data });
    } catch (error) {}
    this.setState({ loading: false });
  };

  submit = async (e) => {
    let value = {
      subscriptionId: this.props.match.params.id,
      device: "Web",
    };
    // console.log(value);
    const { data } = await Buy(value);
    console.log("ssss", window.location.href);
    if (data.data) {
      window.open(data.data.payLink);
      // window.location.href = data.data.payLink;
    }
  };

  // edit = e => {
  //   // this.setState({ name: e.target.value });
  //   SweetAlert({
  //     allowOutsideClick: true,
  //     width: "80vw",
  //     html: (
  //       <div className="edit_profile_div">
  //         <div className="edit_profile">ویرایش نام کاربری</div>
  //         <input
  //           onChange={e => {
  //             this.setState({ stateName: e.target.value });
  //           }}
  //         />
  //         <div>
  //           <Button gray className="profile_button" onClick={this.submit}>
  //             ثبت
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               Swal.close();
  //             }}
  //             gray
  //             className="profile_button"
  //           >
  //             انصراف
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   });
  // };

  render() {
    const { subscription } = this.state;
    return (
      <div className="pagerun">
        <FlexboxGrid.Item
          componentClass={Col}
          className="play display  "
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <FlexboxGrid.Item
            componentClass={Col}
            className=" display   buyit flexd index"
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <div className=" display flexd   buyTitle">
              <p>خرید اشتراک</p>
              <div className="marginbot">
                <Card />
              </div>
            </div>
            <div className="marginbot matn">
              <div className="marginbot">
                <p className="inlineB bold">نام اشتراک</p>
                <p className="inlineB useredit mt"> {subscription.name}</p>
              </div>
              <div className="marginbot">
                <p className="inlineB bold"> مدت زمان</p>
                <p className="inlineB useredit mt">
                  {" "}
                  {subscription.duration} روز
                </p>
              </div>
              <div className="marginbot">
                <p className="inlineB bold"> قیمت</p>
                <p className="inlineB useredit mt">
                  {" "}
                  {subscription.price} ریال
                </p>
              </div>
            </div>
            {this.state.loading ? (
              <div className="loading_apge">
                <div class="lds-dual-ring"></div>
              </div>
            ) : (
              ""
            )}
            <ButtonToolbar className="marginbot marginbottom">
              {this.state.me.subscription.priority <
              this.state.subscription.priority ? (
                <>
                  <Button onClick={this.submit} className="buyButton">
                    خرید
                  </Button>
                  <Button
                    onClick={() => window.history.back()}
                    className="buyButton buyborder "
                  >
                    انصراف
                  </Button>
                </>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    style={{ marginBottom: "10px", width: "100%" }}
                    className="buyButton"
                  >
                    اشتراک شما بالاتر میباشد
                  </Button>
                  <Button
                    style={{ width: "100%", marginBottom: "10px" }}
                    onClick={() => window.history.back()}
                    className="buyButton buyborder "
                  >
                    انصراف
                  </Button>
                </div>
              )}
            </ButtonToolbar>
          </FlexboxGrid.Item>
        </FlexboxGrid.Item>
      </div>
    );
  }
}

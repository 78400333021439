import { APIClient } from "./_api";

function Subscription() {
  return APIClient.get(`/Subscription`);
}

function History() {
  return APIClient.get(`/UserSubscription`);
}

function SubscriptionById(id) {
  return APIClient.get(`/Subscription/${id}`);
}
export { Subscription, SubscriptionById, History };

import React, { Component } from "react";
import "./style.css";

import "rc-slider/assets/index.css";
import { sliders } from "../../api/sliders";
import "react-owl-carousel2/src/owl.carousel.css";
import { FlexboxGrid, Col, Carousel } from "rsuite";

export default class ZoodMarketSlider extends Component {
  state = {
    sliders: [],
  };
  componentDidMount() {
    this.getSlider();
  }

  getSlider = async () => {
    const { data } = await sliders();
    this.setState({ sliders: data.data });
  };
  render() {
    return (
      <FlexboxGrid.Item
        componentClass={Col}
        className="display"
        // lg={24}
        // md={24}
        sm={24}
        xs={24}
      >
        <Carousel
          autoplay
          style={{ direction: "ltr" }}
          className="custom-slider slider Bshadow margint "
        >
          {this.state.sliders &&
            this.state.sliders.map((item, key) => (
              <div className="imgsize">
                <img width="100%" height="100%" src={item.picture} alt="" />
                {/* <h7 className="absolute posh7">{item.title}</h7> */}
              </div>
            ))}
        </Carousel>
      </FlexboxGrid.Item>
    );
  }
}

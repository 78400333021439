export let getTotal = (cart) => {
  let totalCount = [];
  let totalPrice = [];
  if (cart && cart.length) {
    cart.forEach((x) => {
      totalPrice.push(x.price * x.count);
      totalCount.push(x.count);
    });
    totalCount = totalCount.reduce(myFunc);
    totalPrice = totalPrice.reduce(myFunc);

    let total = { totalCount, totalPrice };

    return total;
  } else {
    let total = { totalCount: 0, totalPrice: 0 };

    return total;
  }
};

function myFunc(total, num) {
  return total + num;
}

import React, { Component } from "react";

import "./index.css";

import Content from "./text";
import Header from "../../../components/Layouts/MainHeader";

class Help extends Component {
  state = {
    aboutText: [],
  };

  render() {
    return (
      <>
        <Header
          pageTitle={"راهنمای خرید و سوالات متداول"}
          back={true}
          bg={true}
          edit={false}
        />
        <div className="columns pg_content categoryList">
          <Content>
            <div className="Helpdiv">
              <h5>
                <span>۱</span> سفارش‌ها در چه محدوده‌ای از شهر قم ارسال می‌شود؟
              </h5>
              <p>ما به تمامی نقاط قم ارسال سفارش داریم.</p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۲</span>آیا می‌توانم به اطلاعات خرید خود دسترسی داشته
                باشم؟
              </h5>
              <p>
                بله از طریق سوابق خرید می‌توانید به اطلاعات خرید‌های خود دسترسی
                داشته باشید.
              </p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۳</span>آیا قیمت محصولات سایت به روز است؟
              </h5>
              <p>بله تمامی قیمت‌ها به روز هستند</p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۴</span>پرداخت وجه سفارش به چه صورت است؟
              </h5>
              <p>
                پرداخت بصورت نقدی با اسکناس و یا دستگاه کارتخوان، همچنین بصورت
                آنلاین از طریق شارژ کیف پول، امکان پذیر است و توصیه مدیر فروشگاه
                زود مارکت برای پرداخت وجه فاکتور، استفاده از کیف پول داخل اپ
                می‌باشد.
              </p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۵</span> سفارش‌ها در چه محدوده‌ای از شهر قم ارسال می‌شود؟
              </h5>
              <p>ما به تمامی نقاط قم ارسال سفارش داریم.</p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۶</span>آیا می‌توانم به اطلاعات خرید خود دسترسی داشته
                باشم؟
              </h5>
              <p>
                بله از طریق سوابق خرید می‌توانید به اطلاعات خرید‌های خود دسترسی
                داشته باشید.
              </p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۷</span>آیا قیمت محصولات سایت به روز است؟
              </h5>
              <p>بله تمامی قیمت‌ها به روز هستند</p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۸</span>پرداخت وجه سفارش به چه صورت است؟
              </h5>
              <p>
                پرداخت بصورت نقدی با اسکناس و یا دستگاه کارتخوان، همچنین بصورت
                آنلاین از طریق شارژ کیف پول، امکان پذیر است و توصیه مدیر فروشگاه
                زود مارکت برای پرداخت وجه فاکتور، استفاده از کیف پول داخل اپ
                می‌باشد.
              </p>
            </div>{" "}
            <div className="Helpdiv">
              <h5>
                <span>۹</span> سفارش‌ها در چه محدوده‌ای از شهر قم ارسال می‌شود؟
              </h5>
              <p>ما به تمامی نقاط قم ارسال سفارش داریم.</p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۱۰</span>آیا می‌توانم به اطلاعات خرید خود دسترسی داشته
                باشم؟
              </h5>
              <p>
                بله از طریق سوابق خرید می‌توانید به اطلاعات خرید‌های خود دسترسی
                داشته باشید.
              </p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۱۱</span>آیا قیمت محصولات سایت به روز است؟
              </h5>
              <p>بله تمامی قیمت‌ها به روز هستند</p>
            </div>
            <div className="Helpdiv">
              <h5>
                <span>۱۲</span>پرداخت وجه سفارش به چه صورت است؟
              </h5>
              <p>
                پرداخت بصورت نقدی با اسکناس و یا دستگاه کارتخوان، همچنین بصورت
                آنلاین از طریق شارژ کیف پول، امکان پذیر است و توصیه مدیر فروشگاه
                زود مارکت برای پرداخت وجه فاکتور، استفاده از کیف پول داخل اپ
                می‌باشد.
              </p>
            </div>
          </Content>
        </div>
      </>
    );
  }
}

export default Help;

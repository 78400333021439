import React, { Component } from "react";

import Header from "../../components/Layouts/MainHeader";

import "./style.css";
// import Swal from "../../components/Alart-Sweet";

export default class Credit extends Component {
  render() {
    return (
      <>
        <div className="HeaderFather">
          <Header
            pageTitle={this.props.match.params.title}
            back={true}
            search={true}
            bg={true}
            edit={false}
          />
        </div>
        <div className="credit_content">
          <h4 className="invite">
            با هر دعوت 3000 تومن اعتبار رایگان دریافت کنید
          </h4>
          <h5 className="text_credit"> کد زیر رو برای دوستانت بفرس</h5>
          <h5 className="text_credit">
            {" "}
            پس از اولین خرید هر کدام از انها ،اعتبار هدیه به حساب کیف شما واریز
            خواهد شد
          </h5>
          <div className="div_code">
            <div className="code"> 45778</div>
          </div>
          <ul className="credit_li">
            <li style={{ background: "rgb(222, 222, 223)" }}>گزارش فعالیت</li>
            <li style={{ display: "flex", justifyContent: " space-between" }}>
              <div> تعداد دعوت </div>
              <div> 0 نفر </div>
            </li>
            <li style={{ display: "flex", justifyContent: " space-between" }}>
              <div> دعوت های استفاده شده </div>
              <div> 0 نفر </div>
            </li>
            <li style={{ display: "flex", justifyContent: " space-between" }}>
              <div> در امد کسب شده </div>
              <div> 0 تومن </div>
            </li>
            <li style={{ background: "rgb(222, 222, 223)" }}>
              <h4>لیست دعوت های استفاده شده</h4>
            </li>
            <li style={{ display: "flex", justifyContent: " space-around" }}>
              <div> نام کاربری </div>
              <div> وضعیت </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

import React, { Component } from "react";

// import './index.css';

// API
import * as Acount from "../../../api/account";
import { setAuthToken } from "../../../api/_api";

// Lang

// Components

import * as Yup from "yup";

import {
  FlexboxGrid,
  Col,
  Form,
  FormGroup,
  FormControl,
  ButtonToolbar,
  Button,
} from "rsuite";

export default class LoginForm extends Component {
  state = {
    id1: true,
    id2: false,
    id3: false,
    id4: false,
    phoneNumber:
      this.props && this.props.data && this.props.data.phoneNumber
        ? this.props.data.phoneNumber
        : localStorage.getItem("phoneNumber"),
    plus: "",
  };
  initialValues = {
    phoneNumber:
      this.props && this.props.data && this.props.data.phoneNumber
        ? this.props.data.phoneNumber
        : localStorage.getItem("phoneNumber"),
  };

  handleSubmit = async () => {
    // this.props.goNext(2);
    // this.props.loginData(this.state.initialValue);
    await this.setState({
      verify: true,
      // phoneNumber: this.initialValue.phoneNumber,
      password: null,
    });
    try {
      let newValues = {
        phoneNumber: this.state.phoneNumber,
        verifyCode: this.state.plus,
      };
      let data = await Acount.login(newValues);
      localStorage.setItem("status", data.statusText);
      localStorage.setItem("phoneNumber", this.state.phoneNumber);

      localStorage.setItem("token", data.data.data.Token);
      setAuthToken(data.data.data.Token);
      this.props.redirect();
      // this.props.loginData(this.state);
      // this.props.goNext();
    } catch (error) {
      console.error(error);
    }
  };

  ValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required()
      .min(11)
      .max(11)
      .matches(
        /^(09)(10|11|12|13|14|15|16|17|18|19|90|91|32|30|33|35|36|37|38|39|01|02|03|04|05|41|20|21|22)\d{7}/,
        "لطفا شماره موبایل معتبر وارد نمایید"
      ),
  });

  backPage = () => {
    this.props.goNext(1);
    localStorage.setItem("status", "");
    localStorage.setItem("phoneNumber", "");
  };

  // onChange =async (name, value) => {
  //  await this.setState({
  //     initialValue: { ...this.state.initialValue, [name]: value },
  //   });
  // };

  onChange = async (name, value) => {
    if (name === "1") {
      document.getElementById("id2").focus();
    } else if (name === "2") {
      document.getElementById("id3").focus();
    } else if (name === "3") {
      document.getElementById("id4").focus();
    }
    await this.setState({
      numbers: { ...this.state.numbers, [name]: value },
    });
    let plus =
      this.state.numbers[1] +
      this.state.numbers[2] +
      this.state.numbers[3] +
      this.state.numbers[4];
    this.setState({ plus });
  };

  sendCode = async () => {
    await Acount.checkMobile(this.initialValues);
  };

  render() {
    // const status = localStorage.getItem("status")
    // if (status ==="Created") {
    //   this.props.goNext(2);
    // }
    return (
      <div className="backgroundColor">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="background form"
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <Form className="display flexj flexd form2">
              <FormGroup className="intro">
                <FormGroup className="info">
                  <p className="color h5 padding"> تایید کد ورودی</p>

                  <p className="colorGray p padding">
                    کد 4 رقمی که برای شماره تلفن
                    {this.props.data.phoneNumber
                      ? this.props.data.phoneNumber
                      : localStorage.getItem("phoneNumber")}{" "}
                    ارسال شده وارد کنید.
                  </p>
                </FormGroup>
                <FormGroup className="display width3  mtop">
                  <FormControl
                    id="id4"
                    autoFocus={this.state.id4}
                    maxlength="1"
                    onChange={(e) => this.onChange("4", e)}
                    className="input codeSize"
                  />
                  <FormControl
                    id="id3"
                    autoFocus={this.state.id3}
                    maxlength="1"
                    onChange={(e) => this.onChange("3", e)}
                    className="input codeSize"
                  />
                  <FormControl
                    id="id2"
                    focus={this.state.id2}
                    maxlength="1"
                    onChange={(e) => this.onChange("2", e)}
                    className="input codeSize "
                  />
                  <FormControl
                    autoFocus={this.state.id1}
                    maxlength="1"
                    onChange={(e) => this.onChange("1", e)}
                    className="input codeSize"
                  />
                </FormGroup>
                <FormGroup className="display inputSize align ">
                  <div>
                    <p style={{ cursor: "pointer" }} onClick={this.sendCode}>
                      ارسال دوباره کد در 02:20{" "}
                    </p>
                    <p>
                      شماره اشتباه است ؟{" "}
                      <a
                        class="colorpink"
                        onClick={this.backPage}
                        style={{ cursor: "pointer" }}
                        href
                      >
                        تغییر دهید
                      </a>
                    </p>
                  </div>
                </FormGroup>
              </FormGroup>
              <FormGroup className="display fgap">
                <ButtonToolbar>
                  <Button
                    onClick={this.handleSubmit}
                    className="button inputSize"
                  >
                    {" "}
                    تکمیل ثبت نام
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

import React, { Component } from "react"

// import './index.css';

// API
import * as Acount from "../../../api/account"
import { NavLink } from "react-router-dom"

// Lang

// Components
import * as Yup from "yup"

import {
  FlexboxGrid,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonToolbar,
  Button,
  Icon
} from "rsuite"

export default class LoginForm extends Component {
  state = {
    phoneNumber: null,
    showCheck: false
  }
  initialValues = {
    phoneNumber: ""
  }

  handleSubmit = async () => {
    try {
      await this.setState({
        verify: true,
        phoneNumber: this.state.initialValue.phoneNumber,
        password: null
      })
      try {
        const { status } = await Acount.checkMobile(this.state.initialValue)

        localStorage.setItem("status", status)
        localStorage.setItem("phoneNumber", this.state.initialValue.phoneNumber)
        this.props.sendStatus(status)
        if (status === 201 || status === 202) {
          this.props.goNext(2)
          this.props.loginData(this.state.initialValue)
        } else if (status === 200 || status === 226) {
          this.props.goNext(3)
          this.props.loginData(this.state.initialValue)
        }
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  onChange = async (name, value) => {
    await this.setState({
      initialValue: { ...this.state.initialValue, [name]: value }
    })
    if (value.length === 11) {
      this.setState({ showCheck: true })
    } else {
      this.setState({ showCheck: false })
    }
  }

  ValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required()
      .min(11)
      .max(11)
      .matches(
        /^(09)(10|11|12|13|14|15|16|17|18|19|90|91|32|30|33|35|36|37|38|39|01|02|03|04|05|41|20|21|22)\d{7}/,
        "لطفا شماره موبایل معتبر وارد نمایید"
      )
  })

  render() {
    const status = localStorage.getItem("status")
    if (status === 201) {
      this.props.goNext(2)
    }
    if (status === 200) {
      this.props.goNext(3)
    }
    return (
      <>
        <div className="backgroundColor">
          <FlexboxGrid>
            <FlexboxGrid.Item
              className="background form "
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Form className="display flexj flexd form2">
                <FormGroup className="intro">
                  <div className="inputSize height padding">
                    <p className="color h5 "> ورود</p>
                  </div>
                  <div className="padding">
                    <ControlLabel className="colorGray">شماره تلفن</ControlLabel>
                  </div>
                  <div className="rel">
                    {this.state.showCheck ? (
                      <Icon icon="check" size="lg" className="absolute check" />
                    ) : (
                      ""
                    )}

                    {/* display:none هست */}
                    <FormControl
                      name="phoneNumber"
                      onChange={(e) => this.onChange("phoneNumber", e)}
                      className="input inputSize margin "
                    />
                  </div>
                  <div className="marginbot">
                    <p className="colorGray ">
                      <NavLink to={`/main/condition`} class="colorpink a">
                        {" "}
                        قوانین و مقررات{" "}
                      </NavLink>
                      نرم افزار آموزشی آوا را خوانده و قبول می کنم
                    </p>
                  </div>
                </FormGroup>

                <FormGroup className="display fgap">
                  <ButtonToolbar>
                    {this.state.showCheck ? (
                      <Button onClick={this.handleSubmit} className="button inputSize">
                        ثبت نام
                      </Button>
                    ) : (
                      ""
                    )}
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
      </>
    )
  }
}

import React from "react";

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <clipPath id="clip-path">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
    <g id="Phone" clip-path="url(#clip-path)">
      <rect
        id="Rectangle_320"
        data-name="Rectangle 320"
        width="16"
        height="16"
        fill="none"
      />
      <path
        id="Path_110"
        data-name="Path 110"
        d="M9.286,11.429,11,9.857a.525.525,0,0,1,.429-.143l.286.143,4,1.857c.286.143.286.286.286.571a3.622,3.622,0,0,1-1.143,2.571,4.092,4.092,0,0,1-3,1.143,8.7,8.7,0,0,1-4-1,18.727,18.727,0,0,1-4-2.857A24.333,24.333,0,0,1,1.714,9,19.747,19.747,0,0,1,.286,5.857,8.791,8.791,0,0,1,0,3.143,3.754,3.754,0,0,1,1,1,4.344,4.344,0,0,1,3.714,0a.742.742,0,0,1,.571.286l2.143,4a.5.5,0,0,0,.143.286A.525.525,0,0,1,6.429,5L4.571,6.714a.638.638,0,0,0,0,.714A13.642,13.642,0,0,0,6.286,9.714a14.424,14.424,0,0,0,2.286,1.857C8.857,11.714,9.143,11.714,9.286,11.429Z"
        fill="#fe2e62"
      />
    </g>
  </svg>
);

import React, { Component } from "react";

// Components
import { FlexboxGrid, Col } from "rsuite";
import Exit from "../../../assets/svg/ava/exite";
import Address from "../../../assets/svg/ava/address";
import Phone from "../../../assets/svg/ava/phone";
import ava from "../../../assets/images/ava/Rectangle 1496.png";
import Instagram from "../../../assets/svg/ava/insta";
import Telegram from "../../../assets/svg/ava/telegram";
import Site from "../../../assets/svg/ava/site";

class AboutSystem extends Component {
  state = {
    aboutText: [],
  };

  render() {
    return (
      <div className="backgroundColor pagerun">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="user display min"
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <FlexboxGrid.Item
              className=" aboutus   display  margint"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <FlexboxGrid.Item
                className="display flexd  "
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => window.history.back()}
                  className=" display exit"
                >
                  <Exit />
                </div>
                <div>
                  <div className="circle display">
                    <img src={ava} alt="" />
                  </div>
                </div>

                <div className="display flexd">
                  <h5 className="color h5 back toping">نرم افزار آموزشی آوا</h5>

                  <p className="about">
                    گروه آموزشی آوا، گروهی مستقل و تخصصی است که با هدف ارتقای
                    سطح دانش فارغ‌التحصیلان نقطع کارشناسی روان‌شناسی تشکیل شده
                    است و به طور خاص به فیلد کلینیکال علاقمند بوده و در تلاش‌های
                    مکرر خویش قصد دارد تا مقاطع کارشناسی ارشد و پی‌اچ‌دی را نیز،
                    تحت پوشش قرار دهد. شپا می‌توانید به طور مستمر از داده‌های ما
                    در اپلیکیشن ، پیج اینستاگرام و تلگرام بهره‌مند شوید.
                  </p>

                  <div className="display flexd pad">
                    <h5 className="h5 colorpink back"> ارتباط با ما </h5>
                    <div className="marginbot about">
                      <div className="marginbot">
                        <Phone />
                        <p className="inlineB marginr"> شماره تماس</p>
                        <p className="inlineB useredit mt"> ۰۲۵۳۸۸۱۶۰۰۵</p>

                        <br />
                        <p className="inlineB useredit mt"> ۰۹۳۵۴۹۷۴۲۸۴</p>
                        <br />
                        <p className="inlineB useredit mt"> ۰۹۰۳۷۰۸۲۴۳۶</p>
                        <br />
                        <p className="inlineB useredit mt"> ۰۹۳۹۴۰۷۷۷۳۷</p>
                        <br />
                        <p className="inlineB useredit mt"> ۰۹۳۹۴۰۷۷۷۳۸</p>
                      </div>
                      <div className="marginbot adres">
                        <Address />
                        <p className="inlineB marginr ">آدرس</p>
                        <div className="inlineB useredit mt psyad">
                          <p className="inlineB useredit mt adres2">
                            قم. نیروگاه. خیابان توحید. ابتدای توحید ۹. پلاک ۱۷۱.
                            طبقه دوم. مرکز خدمات روان‌شناختی و مشاوره آوا
                          </p>
                        </div>
                      </div>
                      <div className="marginbot">
                        <Site />
                        <p className="inlineB marginr "> وب سایت </p>
                        <p className="inlineB useredit mt"> Ava-edugroup.ir</p>
                      </div>
                      <div className="marginbot">
                        <Instagram />
                        <p className="inlineB marginr "> اینستاگرام</p>
                        <p className="inlineB useredit mt"> psychointern</p>
                      </div>
                      <div className="marginbot">
                        <Telegram />
                        <p className="inlineB marginr "> تلگرام</p>
                        <p className="inlineB useredit mt"> psychointern</p>
                      </div>
                    </div>
                  </div>
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

export default AboutSystem;

import React from "react";

export default () => (
  <svg width="7.793" height="12.123" viewBox="0 0 7.793 12.123">
    <g id="speech" transform="translate(-242 -60.986)">
      <path
        id="Path_1267"
        data-name="Path 1267"
        d="M304.165,68.779a2.167,2.167,0,0,0,2.165-2.165V63.151a2.165,2.165,0,1,0-4.33,0v3.464A2.167,2.167,0,0,0,304.165,68.779Zm-1.3-5.628a1.3,1.3,0,0,1,2.6,0v3.464a1.3,1.3,0,0,1-2.6,0Z"
        transform="translate(-58.268)"
        fill="#c1c1ca"
      />
      <path
        id="Path_1268"
        data-name="Path 1268"
        d="M249.36,180.986a.433.433,0,0,0-.433.433v1.732a3.031,3.031,0,1,1-6.061,0v-1.732a.433.433,0,1,0-.866,0v1.732a3.9,3.9,0,0,0,3.464,3.872v1.756h-1.3a.433.433,0,0,0,0,.866h3.464a.433.433,0,0,0,0-.866h-1.3v-1.756a3.9,3.9,0,0,0,3.464-3.872v-1.732A.433.433,0,0,0,249.36,180.986Z"
        transform="translate(0 -116.536)"
        fill="#c1c1ca"
      />
    </g>
  </svg>
);

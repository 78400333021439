import React from "react";

export default () => (
  <svg width="10.645" height="10.645" viewBox="0 0 10.645 10.645">
    <g
      id="Group_443"
      data-name="Group 443"
      transform="translate(-37.646 -101.146)"
    >
      <line
        id="Line_28"
        data-name="Line 28"
        x1="9.938"
        y2="9.938"
        transform="translate(38 101.5)"
        fill="none"
        stroke="#fe2e62"
        stroke-width="1"
      />
      <line
        id="Line_29"
        data-name="Line 29"
        x2="9.938"
        y2="9.938"
        transform="translate(38 101.5)"
        fill="none"
        stroke="#fe2e62"
        stroke-width="1"
      />
    </g>
  </svg>
);

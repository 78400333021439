import React, { Component } from "react";

// Components
import Header from "../../components/Layouts/MainHeader";

import { NavLink } from "react-router-dom";
import NoImage from "../../assets/images/avatars/empty2.png";

import "./style.css";

class ProductDetails extends Component {
  state = {
    product: [],
    products: [
      {
        id: "13602",
        cat1: "1",
        cat2: "22",
        name: "سیم اسکاچ سخت رافونه",
        price2: "2900",
        price3: "2900",
        amount: "36",
        standard: "عدد",
        min_order: "1",
        max_order: "100",
        image1:
          "https://zoodmarket-app.ir/webservice/images/image-items/6128082881.png",
        image2:
          "https://zoodmarket-app.ir/webservice/images/image-items/no_image.png",
        image3:
          "https://zoodmarket-app.ir/webservice/images/image-items/no_image.png",
        comment: "",
      },
    ],
  };
  componentDidMount() {}

  getProductDetails = async () => {};

  render() {
    const { props } = this;
    // console.log("props", props);

    return (
      <>
        <div className="HeaderFather">
          <Header
            pageTitle={props.match.params.title}
            back={true}
            search={true}
            bg={true}
            edit={false}
          />
        </div>

        <div className="row-product">
          <div className="row-detail-product-div">
            {this.state.products.map((product) => {
              return (
                <>
                  <div className="productDetails_place_box">
                    <NavLink
                      to={`/main/productDetails/${product.id}/${product.name}`}
                    >
                      <div className="content">
                        <div className="image_mask">
                          <img
                            src={product.image1 ? product.image1 : NoImage}
                            alt=""
                          />
                        </div>
                        <div className="title">{product && product.name}</div>
                        <div className="price">{product && product.price2}</div>
                      </div>
                    </NavLink>

                    <button className="product_button">
                      {" "}
                      افزودن به سبد خرید
                    </button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default ProductDetails;

import React, { Component } from "react";
import Header from "../../components/Layouts/MainHeader";
import SadSvg from "../../assets/svg/sad";
import "./style.css";
export default class LastPayment extends Component {
  render() {
    return (
      <div>
        <div className="HeaderFather">
          <Header
            pageTitle={"زود مارکت - قم"}
            search={true}
            bg={true}
            edit={false}
          />
          <div className="noProduct">
            <div className="noProductContent">
              <SadSvg />
              <div className="not_productError">
                شما هنوز از این فروشگاه خرید نکرده‌اید!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

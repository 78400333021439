import React, { Component } from "react";

import Logo from "../../assets/images/logo/Comp.gif";
import "./Startup.css";

// Components
import { Redirect } from "react-router-dom";
import Login from "../Authentication/Login";
import { LangContextConsumer } from "../../components/languageContext";

export default class Startup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loading_timer: true,
      redirect: false,
      step1: true,
      step2: false,
      allWords: [],
    };
  }

  async componentDidMount() {
    this.GetToken();
    this.loader().then(() => {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ loading_timer: false });
      }, 600);
    });
  }

  GetToken() {
    const token = localStorage.getItem("token");
    if (token) {
      this.setState({ redirect: true });
    } else {
      this.setState({ redirect: false });
    }
  }

  loader() {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), 3400);
    });
  }

  step1 = () => {
    this.setState({
      step1: false,
      step2: true,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/main/home`} />;
    }
    return (
      <>
        {this.state.loading_timer ? (
          <div className={`main-loader ${this.state.loading ? `true` : ``}`}>
            <img src={Logo} alt="ava" />
          </div>
        ) : null}
        {/* <div className="login_wrapper"> */}
        <div className="">
          {this.state.step1 ? (
            <div className="step2">
              <div className="">
                <Login />
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
Startup.contextType = LangContextConsumer;

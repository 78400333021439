import React from "react";

export default () => (
  <svg id="Web" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Path_4"
      data-name="Path 4"
      d="M3,3a9.078,9.078,0,0,1,7-3,9.078,9.078,0,0,1,7,3,9.078,9.078,0,0,1,3,7,9.078,9.078,0,0,1-3,7,9.078,9.078,0,0,1-7,3,9.078,9.078,0,0,1-7-3,9.98,9.98,0,0,1-3-7A9.078,9.078,0,0,1,3,3Zm8.333,15a4.1,4.1,0,0,0,2.5-1.5A7.28,7.28,0,0,0,15,13a2.76,2.76,0,0,0-.833-2A2.934,2.934,0,0,0,12,10H10.333a4.866,4.866,0,0,1-1.5-.333,1.513,1.513,0,0,1-.5-1.167.866.866,0,0,1,.333-.667A1.264,1.264,0,0,1,9.333,7.5a1.138,1.138,0,0,1,.833.5c.333.167.5.333.667.333a1,1,0,0,0,.667-.167,1,1,0,0,0,.167-.667,2.652,2.652,0,0,0-.833-1.667,6.894,6.894,0,0,0,.833-3.167.358.358,0,0,0-.333-.333A5.152,5.152,0,0,0,10,2,8.337,8.337,0,0,0,5.667,3.333a4.2,4.2,0,0,0-1.5,3.333A4.267,4.267,0,0,0,5.5,9.833a4.553,4.553,0,0,0,3.167,1.333h0v.667A2.14,2.14,0,0,0,9.333,13.5a2.427,2.427,0,0,0,1.5,1v3c0,.167,0,.167.167.333S11.167,18,11.333,18Z"
      fill="#fe2e62"
    />
  </svg>
);

import React, { Component } from "react";
import { FlexboxGrid, Col, FormGroup, ButtonToolbar, Button } from "rsuite";
import Header from "../../../components/Layouts/MainHeader";
import { NavLink } from "react-router-dom";

export default class Conditions extends Component {
  goLogin = () => {
    window.location.replace("/login");
  };
  render() {
    return (
      <div className="backgroundColor">
        <FlexboxGrid>
          <FlexboxGrid.Item
            className="user "
            componentClass={Col}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <Header
              pageTitle={this.props.match.params.title}
              // back={true}
              search={true}
              bg={true}
              edit={false}
            />
            <FlexboxGrid.Item
              className=" userdetail display   form3 margint "
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <div className="summery  display  flexd rt ">
                <div className="condimatn ">
                  <h6> مقررات</h6>

                  <div>
                    <p>
                      آوایی عزیز، سلام سیاحت هستم حمیدرضا، روان‌شناس بالینی. از
                      اینکه اپلیکیشن گروه آموزشی آوا را برای آموزش تخصصی
                      روان‌شناسی انتخاب کرده‌اید، از شما سپاس‌گذارم. اپ ما، یک
                      اپلیکیشن مستقل از تمام منابعی است که تا کنون با ایشان در
                      ارتباط بوده‌اید. از جمله وزارت بهداشت، وزارت علوم،
                      دانشگاه‌ها، و یا هر موسسه آموزشی دیگر. بنابراین از شما
                      دعوت می‌کنم قبل از اینکه بخواهید از این مجموعه آموزشی
                      استفاده کنید، نسبت به قوانین و مقررات آن مطالعه کافی و
                      کامل داشته باشید تا در ادامه استفاده از اپلیکیشن دچار
                      مساله نشوید.
                    </p>
                    <p>
                      ۱. آگاه باشید که از ارایه نام کاربری و رمز خودتان به
                      دیگران خودداری کنید. در غیر اینصورت از ادامه استفاده شما
                      از منابع اپلیکیشن جلوگیری خواهد شد.
                    </p>
                    <p>
                      ۲. بدانید که هرگونه دانلود کردن (با استفاده از اپ‌های
                      موجود) و یا اسکرین گرفتن از ویدیوها، ناقض حقوق ناشر است و
                      به محض اطلاع، نام کاربری شما باطل خواهد شد و از ادامه
                      استفاده‌تان جلوگیری می‌گردد.
                    </p>
                    <p>
                      ۳. هزینه اشتراک شما در سه پلن طلایی، نقره‌ای و برنزی تعریف
                      شده است که در صورت نیازتان می‌توانید آن را تمدید کنید اما
                      در صورت انصراف هزینه‌تان باز گردانده نمی‌شود. بنابراین در
                      انتخاب اشتراکتان دقت کنید.
                    </p>
                    <p>
                      ۴. برای کلیه کارآموزانی که به صورت حضوری در جلسات شرکت
                      می‌کنند، نام کاربری و رمز رایگان تخصیص داده می‌شود و ایشان
                      می‌توانند در زمان دوره تحصیلشان از داده‌های موجود در
                      اپلیکیشن به صورت رایگان بهره مند شوند.
                    </p>
                    <p>
                      ۵. در صورتی که از دوره‌های حضوری آموزش بهره‌مند شده‌اید،
                      حتما مدیر اپ را مطلع سازید تا استفاده از اپ برایتان رایگان
                      شود.
                    </p>
                    <p>
                      ۶. اطلاعات پرداختی شما از درگاه آیدی‌پی مورد تایید قرار
                      می‌گیرد. بنابراین استفاده‌های غیر مجاز از جمله فیشینگ‌ها،
                      سوء‌استفاده از کارت‌های بانکی و... بر عهده خود مشترک است و
                      مسئولیتی بر عهده گروه آموزشی آوا وجود ندارد.
                    </p>
                    <p>
                      ۷. در صورتی که از اپ استفاده کردید اما به نتیجه لازم آموزش
                      نرسیدید می‌توانید با مدیر گروه آموزشی آوا، نسبت به ادامه
                      آموزش در ارتباط باشید تا مساله بررسی و برطرف گردد.
                    </p>
                  </div>
                </div>
                <FormGroup className="display fgap condi">
                  <ButtonToolbar>
                    {/* <NavLink to={`/login`} class="a"> */}
                    <Button
                      onClick={this.goLogin}
                      className="button marginbot inputSize"
                    >
                      قبول می کنم{" "}
                    </Button>
                    {/* </NavLink> */}
                  </ButtonToolbar>
                </FormGroup>
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    );
  }
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../components/Layouts/MainHeader";
// import Button from "../../components/button";
import Swal from "../../components/Alart-Sweet/sweet-alert";
import Menu from "../../components/Layouts/Main_menu";

import { History } from "../../api/subscription";

import {
  FlexboxGrid,
  Col,
  Form,
  FormGroup,
  ButtonToolbar,
  Button,
} from "rsuite";
import color from "@material-ui/core/colors/amber";

export default class ProfileHistory extends Component {
  state = {
    name: "محمد کمال زاده",
    stateName: "",
    data: [],
  };

  componentDidMount() {
    this.getHistory();
  }

  getHistory = async () => {
    this.setState({ loading: true });

    try {
      let { data } = await History();
      data = data.data.filter((x) => x.priority !== 0);
      console.log(data);
      // console.log(data);
      this.setState({ data });
    } catch (error) {}
    this.setState({ loading: false });
  };

  submit = (e) => {
    this.setState({ name: this.state.stateName });
    Swal.close();
  };

  // edit = e => {
  //   // this.setState({ name: e.target.value });
  //   SweetAlert({
  //     allowOutsideClick: true,
  //     width: "80vw",
  //     html: (
  //       <div className="edit_profile_div">
  //         <div className="edit_profile">ویرایش نام کاربری</div>
  //         <input
  //           onChange={e => {
  //             this.setState({ stateName: e.target.value });
  //           }}
  //         />
  //         <div>
  //           <Button gray className="profile_button" onClick={this.submit}>
  //             ثبت
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               Swal.close();
  //             }}
  //             gray
  //             className="profile_button"
  //           >
  //             انصراف
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   });
  // };

  render() {
    return (
      <>
        <div className="backgroundColor">
          <FlexboxGrid>
            <FlexboxGrid.Item
              className="user"
              componentClass={Col}
              lg={24}
              md={24}
              style={{ padding: "0" }}
              sm={24}
              xs={24}
            >
              <Header
                pageTitle={this.props.match.params.title}
                back={true}
                search={true}
                bg={true}
                edit={false}
              />

              <FlexboxGrid.Item
                className="userdetail display flexd "
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                {this.state.loading ? (
                  <div className="loading_apge">
                    <div class="lds-dual-ring"></div>
                  </div>
                ) : (
                  ""
                )}
                <Form className="display flexd margint history">
                  {this.state.data
                    ? this.state.data.map((x) => {
                        return (
                          <div className="gold display color shadow goldmargin rightanime">
                            <div className="width display start">
                              <div className="margindate">
                                <small
                                  // style={{ color: x.color }}
                                  className="date"
                                >
                                  {x.createdAtShamsi}
                                </small>

                                {/* <br /> */}
                                {/* <small className="small">تیر </small> */}
                                {/* <br /> */}

                                {/* <small className="date">98 </small> */}
                              </div>
                              <div>
                                <h7>{x.name}</h7>
                              </div>
                            </div>

                            <div className="width">
                              <p className="histormoney money">
                                {x.price} تومان
                              </p>
                            </div>
                          </div>
                        );
                      })
                    : "هیچ تاریخچه ای ثبت نشده"}

                  <FormGroup className="  fixed">
                    <ButtonToolbar style={{ marginBottom: "50px" }}>
                      <NavLink to={`/main/buy/خرید اشتراک`} class="a">
                        <Button className="button inputSize">
                          خرید اشتراک
                        </Button>
                      </NavLink>
                    </ButtonToolbar>
                  </FormGroup>
                </Form>
              </FlexboxGrid.Item>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Menu />
        </div>
      </>
    );
  }
}

import React from "react";

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <clipPath id="clip-path">
        <rect width="24" height="24" fill="none" />
      </clipPath>
    </defs>
    <g id="Profile" clip-path="url(#clip-path)">
      <path
        id="Union_1"
        data-name="Union 1"
        d="M0,24V21c0-3.3,5.4-6,12-6s12,2.7,12,6v3ZM6,6a6,6,0,1,1,6,6A6,6,0,0,1,6,6Z"
        fill="#252934"
      />
    </g>
  </svg>
);

import { createContext } from 'react';

const {
  Provider: UserContextProvider,
  Consumer: UserContextConsumer
} = createContext();

export { UserContextProvider, UserContextConsumer };



import { APIClient } from "../_api";

const endpoint = "/general_content/type";

function Terms() {
    return APIClient.get(`${endpoint}/3`);
}

function AboutSystem() {
    return APIClient.get(`${endpoint}/2`);
}
function AboutUs() {
    return APIClient.get(`${endpoint}/1`);
}

export { Terms, AboutSystem, AboutUs };

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../components/Layouts/MainHeader";
import Menu from "../../components/Layouts/Main_menu";
import Swal from "../../components/Alart-Sweet/sweet-alert";
import Editpen from "../../assets/svg/ava/editpen";
// import avatar from "../img/MaskGroup3.png";
import { getMe } from "../../api/me";
import {
  FlexboxGrid,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button,
} from "rsuite";

export default class Info extends Component {
  state = {
    name: "محمد کمال زاده",
    stateName: "",
    data: {},
  };

  componentDidMount() {
    this.getMe();
  }

  getMe = async () => {
    const { data } = await getMe();
    console.log(data);
    this.setState({ data: data.data });
  };

  submit = (e) => {
    this.setState({ name: this.state.stateName });
    Swal.close();
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <div className="backgroundColor">
          <FlexboxGrid>
            <FlexboxGrid.Item
              className="user"
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              {console.log(this.props.match.params.title)}
              <Header
                pageTitle={this.props.match.params.title}
                // back={true}
                exit={false}
                edit={true}
                search={true}
                bg={true}
              />
              {/* <div>
                <div className="back"><Back /> </div>
                <h4 className="title"> پروفایل کاربری</h4>
                <div className="logout top">
                  <Editpen />
                </div>
              </div> */}
            </FlexboxGrid.Item>

            <FlexboxGrid.Item
              className=" userdetail display flexd  "
              componentClass={Col}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Form className="display  flexd form2  ">
                <FormGroup className="marginbottom">
                  <div className="avatardad rel mdad">
                    <img src={data.picture} className="avatar" />
                  </div>
                </FormGroup>
                <FormGroup className="marginbottom">
                  <div className="padding">
                    <ControlLabel className="color">شماره تلفن</ControlLabel>
                  </div>
                  <div className="rel">
                    <div className="input inputSize margin displinfo ">
                      {data.phoneNumber}
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="marginbottom">
                  <div className="padding">
                    <ControlLabel className="color">
                      نام و نام خانوادگی
                    </ControlLabel>
                  </div>
                  <div className="rel">
                    <div className="input inputSize margin displinfo">
                      {" "}
                      {data.name}
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="marginbottom">
                  <div className="padding">
                    <ControlLabel className="color">تحصیلات </ControlLabel>
                  </div>
                  <div className="rel">
                    <div className="input inputSize margin displinfo ">
                      {data && data.education && data.education.name}
                    </div>
                  </div>
                </FormGroup>

                <FormGroup className="marginbottom">
                  <div className="padding">
                    <ControlLabel className="color">جنسیت</ControlLabel>
                  </div>
                  <div className="rel">
                    <div className="input inputSize margin displinfo ">
                      {data.sex}
                    </div>
                  </div>
                </FormGroup>
                <NavLink
                  className=""
                  // onClick={()=>this.handleClick(x.id)}
                  to={`/main/profile/پروفایل کاربری`}
                >
                  <Button
                    onClick={this.handleSubmit}
                    className="button inputSize"
                  >
                    تایید
                  </Button>
                </NavLink>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
      </div>
    );
  }
}

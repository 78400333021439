import React from "react";

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <clipPath id="clip-path">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
    <g id="Places" clip-path="url(#clip-path)">
      <rect
        id="Rectangle_305"
        data-name="Rectangle 305"
        width="16"
        height="16"
        transform="translate(0 0)"
        fill="none"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M6.58,9.47A2.786,2.786,0,0,0,9.371,6.679,2.872,2.872,0,0,0,6.58,3.788,2.786,2.786,0,0,0,3.788,6.579,2.942,2.942,0,0,0,6.58,9.47ZM1.894,1.894a6.626,6.626,0,0,1,9.371,9.371L6.58,15.95,1.894,11.265A6.807,6.807,0,0,1,1.894,1.894Z"
        transform="translate(0.975 0.05)"
        fill="#fe2e62"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

import React, { Component } from "react";

import Header from "../../components/Layouts/MainHeader";

// import "./style.css";
// import Swal from "../../components/Alart-Sweet";

export default class wallet extends Component {
  render() {
    return (
      <>
        <div className="HeaderFather">
          <Header
            pageTitle={this.props.match.params.title}
            back={true}
            search={true}
            bg={true}
            edit={false}
          />
        </div>
      </>
    );
  }
}

import React from "react";

export default () => (
  <svg width="9.902" height="9.95" viewBox="0 0 9.902 9.95">
    <path
      id="Path_1342"
      data-name="Path 1342"
      d="M8.392,7.831H5.6a1.115,1.115,0,1,1-2.231,0H.585a.536.536,0,0,1-.5-.392.6.6,0,0,1,.167-.615,2.254,2.254,0,0,0,.892-1.79V3.356a3.346,3.346,0,1,1,6.692,0V5.034a2.254,2.254,0,0,0,.892,1.79.575.575,0,0,1-.335,1.007Z"
      transform="translate(0.451 0.5)"
      fill="none"
      stroke="#c1c1ca"
      stroke-width="1"
      fill-rule="evenodd"
    />
  </svg>
);

import React from "react";

export default () => (
  <svg width="9.9" height="8.355" viewBox="0 0 9.9 8.355">
    <path
      id="Path_1312"
      data-name="Path 1312"
      d="M813.8,402.938h-.607v-.913h.607v-5.676h-.607v-.907h.607V395h-9.9v.441h.625v.907H803.9v5.682h.625v.913H803.9v.411h9.9Zm-7.791-6.589v-.907h.907v.907Zm2.4,0v-.907h.913v.907Zm2.391,0v-.907h.907v.907Zm2.323.613v4.56h-3.69v-4.56Zm-8.294,4.585v-4.561h3.69v4.561Zm1.183,1.391v-.913h.907v.913Zm2.4,0v-.913h.913v.913Zm2.391,0v-.913h.907v.913Z"
      transform="translate(-803.9 -395)"
      fill="#c1c1ca"
    />
  </svg>
);
